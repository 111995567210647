@use "../../global/" as *;

.c-explanation__items {
  background-color: $white;
  padding: rem(24);
  border-radius: rem(8);
  margin-top: rem(32);
  @include mq('md') {
    padding: rem(48);
  }
}

.c-explanation__item:nth-child(n + 2) {
  padding-top: rem(24);
  border-top: rem(1) solid $gray-500;
}

.c-explanation__item:not(:last-child) {
  padding-bottom: rem(15);
}

// ----------
//c-explanation-info
// ----------
.c-explanation-info__title {
  color: $brand-accent;
  font-size: rem(14);
  font-weight: $bold;
  letter-spacing: calc(15 / 100 * 1em);
  line-height: rem(24);
  @include mq('md') {
    font-size: rem(16);
  }
}

.c-explanation-info__description {
  color: #242526;
  font-size: rem(14);
  font-weight: $regular;
  letter-spacing: calc(15 / 100 * 1em);
  line-height: rem(24);
  margin-top: rem(8);
  b {
    font-weight: $bold;
  }
}