@use "../../global/" as *;

// ----------
// l-faq-drawer
// ----------
.l-faq-drawer {
  position: fixed;
  top: 0;
  right: 0;
  z-index: $layer-faq-drawer;
  width: 75%;
  height: 100vh;
  @include mq('md') {
    width: 20%;
  }
}