@use "../../global/" as *;

.p-drawer {
  background-color: $brand-sub;
  transform: translateX(105%);
  transition: transform 0.5s;
}

.p-drawer.is-open {
  transform: translateX(0);
}

.p-drawer__scroll {
  width: 100%;
  height: calc(100vh - #{rem(100)});
  overflow-y: auto;
  padding: rem(32) 0;
  @include mq('md') {
    height: calc(100vh - #{rem(84)});
    padding: rem(105) 0;
  }
}

.p-drawer__inner {
  width: 100%;
  padding: 0 rem(strip-unit($padding-sp));
  @include mq('md') {
    padding: 0 rem(strip-unit($padding-pc));
  }
}

.p-drawer__logo {
  background-color: $white;
  width: rem(247);
  height: rem(strip-unit($header-height-sp));
  display: flex;
  align-items: center;
  border-radius: rem(48);
  padding: 0 rem(24);
  @include mq('md') {
    width: rem(296);
    height: rem(64);
    padding: 0 rem(48);
  }
}

.p-drawer__nav {
  @include mq("md") {
    width: 100%;
    max-width: rem(809);
    margin: 0 auto;
  }
}

.p-drawer__info {
  margin: rem(20) 0 0;
  @include mq("md") {
    width: 100%;
    max-width: rem(809);
    margin: rem(64) auto 0;
  }
}