@use "../global" as *;

*,
*::before,
*::after {
	box-sizing: border-box;
}
/* webkit specific styles */

input[type="color"]::-webkit-color-swatch {
  border: none;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin:0;
  padding:0;
  border:0;
  outline:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
  font-weight:inherit;
}

/* リキッドレイアウト対応 */
html {
  // ~375px
  font-size: vw(375, 16);
  // 375px~767px
  @media screen and (min-width: 375px) {
    font-size: 16px;
  }
  // 768px~1280px
  @include mq("md") {
    font-size: vw(1280, 16);
  }
  // 1280px~
  @include mq("lg") {
    font-size: 16px;
  }
}

body {
	font-family: $font-family-base;
	font-size: $font-size-base;
	font-weight: $font-weight-base;
	color: $text;
	line-height: $line-height-base;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: $white;
	overflow-wrap: break-word;
	min-height: 100vh;
	min-height: -webkit-fill-available;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section { 
  display:block;
}

nav ul {
  list-style:none;
}

blockquote, q {
  quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content:'';
  content:none;
}

a {
  margin:0;
  padding:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
	text-decoration: none;
}

a[href^="tel:"] {
  @include mq("md") {
      pointer-events: none;
  }
}

/* Make images easier to work with */
img,
picture {
	width: 100%;
  max-width: 100%;
	height: auto;
  display: block;
}

/* change colours to suit your needs */
ins {
  background-color:#ff9;
  color:#000;
  text-decoration:none;
}

/* change colours to suit your needs */
mark {
  background-color:#ff9;
  color:#000; 
  font-style:italic;
  font-weight:bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom:1px dotted;
  cursor:help;
}

table {
  border-collapse:collapse;
  border-spacing:0;
}

/* change border colour to suit your needs */
hr {
  display:block;
  height:1px;
  border:0;   
  border-top:1px solid #cccccc;
  margin:1em 0;
  padding:0;
}

input, select {
  vertical-align:middle;
}

input:focus {
  outline: none;
}
ul, ol {
  list-style-type: none;
}

button{
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}