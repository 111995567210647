@use "../../global/" as *;

.p-gnav__items {
  @include mq('md') {
    display: flex;
    height: inherit;
    align-items: center;
  }
}

.p-gnav__item {
  @include mq('md') {
    display: flex;
    height: inherit;
    align-items: center;
    font-size: rem(12);
    font-weight: $bold;
  }
}

.p-gnav__item:nth-child(n + 2) {
  @include mq('md') {
    margin-left: rem(56);
  }
}

.p-gnav__link {
  @include mq('md') {
    display: flex;
    height: inherit;
    align-items: center;
    color: #0A061A;
    transition: color $transition-duration;
  }
}

.p-gnav__link:focus,
.p-gnav__link:hover {
  @include mq('md') {
    color: $brand-main;
  }
}