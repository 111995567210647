// 色
// Brand - pale
$brand-main: #0055A2;
$brand-sub: #004482;
$brand-accent: #CD5954;
$brand-thin: #E6EEF6;
$brand-accentThin: #FBEBEA;
// Gray
$gray-900: #595959;
$gray-700: #747474;
$gray-500: #D1D1D1;
$gray-300: #E4E4E4;
$gray-100: #F0F0F0;
$white: #FFFFFF;
// text
$black: #000000;
$text: #302F37;
$text-thin: #8B90A0;
// other
$footer-bg-color: #302F37;

// マウスホバー
$opacity: 0.7; // 透過度

// フォント
$font-family-base: 'Noto Sans JP', sans-serif;// ベースフォント
$font-family-sub-medium: 'futura-pt', sans-serif;
$font-family-sub-bold: 'futura-pt-bold', sans-serif;
$font-size-base: 16px; // ベースフォントサイズ
$font-weight-base: 400; // ベースフォントウェイト
$line-height-base: 1.5; // ベース行間

// furuta-pt-medium
// font-family: 'futura-pt', sans-serif;
// font-weight: 500;
// font-style: normal;

// furuta-pt-bold
// font-family: 'futura-pt-bold', sans-serif;
// font-weight: 700;
// font-style: normal;

// avenir next
// font-family: 'Avenir Next';
// font-weight: 800;

// font-weight
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;

// ---
// レイアウト
// ---
$inner-pc: 1000px; // PCインナー幅
$inner-sp: 540px; // SPインナー幅

$padding-pc: 40px; // タブレット時の余白
$padding-sp: 24px; // スマホ時の余白

// ブレークポイント（SP基準）
$breakpoints: (
  "sm": "(min-width: 375px)",
  "md": "(min-width: 768px)",
  "inner": "(min-width: #{$inner-pc})", // 1000px
  "lg": "(min-width: 1280px)",
  "xl": "(min-width: 1440px)",
) !default;

// z-index
$layer-modal: 100;
$layer-modal-overlay: 99;
$layer-faq-drawer: 43;
$layer-faq-drawer-overlay: 42;
$layer-hamburger: 41;
$layer-drawer: 40;
$layer-drawer-overlay: 39;
$layer-header: 30;
$layer-floating: 20;
$layer-footer: 10;
$layer-up: 2;
$layer-default: 1;
$layer-background: -1;

// ヘッダーの高さ
$header-height-pc: 64px;
$header-height-sp: 56px;
$fixed-header-height: 72px;

// アニメーション秒数
$transition-duration: 0.4s;