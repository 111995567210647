@use "./variables" as *;
@use "sass:math";
@use "sass:map";

// pxをremに変換
@function rem($pixels) {
  @return math.div($pixels, 16) * 1rem;
}

// pxをvwに変換
@function vw($window_width, $size) {
	@return math.div($size, $window_width) * 100vw;
}

// ---
// メディアクエリー
// ---
@mixin mq($breakpoint: "md") {
	@media #{map.get($breakpoints, $breakpoint)} {
		@content;
	}
}

// @mixin max-screen($max) {
// 	@include mq($max-width: $max) {
// 	  @content;
// 	}
//   }

// 数値のみを取り出す
@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return math.div($number, $number * 0 + 1);
  }
  @return $number;
}

// line-heightの算出
@function lh($font-size: 16, $height: 24) {
  @return math.div($height, $font-size);
}

// line-heightのハーフ・レディングを消す
@mixin lineHeightSpaceFill ($line-height) {
	&::before,
	&::after {
		content: "";
		display: block;
		width: 0;
		height: 0;
	}
	&::before {
		margin-top: calc((1 - #{$line-height}) * 0.5em);
	}
	&::after {
		margin-bottom: calc((1 - #{$line-height}) * 0.5em);
	}
}

// letter-spacingの算出
@function ls($av) {
  @return math.div($av, 1000) * 1em;
}

// text-indentの算出
@function ti($av) {
	@return math.div($av, 1000) * 1em;
}

// 画像のアスペクト比
@function aspect-ratio($side, $height) {
  @return math.div($height, $side) * 100%;
}
