@use "../../global/" as *;

// ----------
// p-faq-drawer-overlay
// ----------
.p-faq-drawer-overlay {
  background-color: rgba($black, 0.48);
  display: none;
  opacity: 0;
  transition: opacity $transition-duration;
}

.p-faq-drawer-overlay.is-show {
  display: block;
  opacity: 1;
}