@use "../../global/" as *;

.c-button-drawer-close {
  display: inline-block;
  width: 100%;
  text-align: center;
  border-radius: rem(8);
  padding: rem(14) rem(21);
  background-color: $white;
  color: $brand-main;
  font-family: inherit;
  font-size: rem(12);
  font-weight: $bold;
  letter-spacing: calc(10 / 100 * 1em);
  line-height: rem(20);
  @include mq('md') {
    font-size: rem(14);
  }
}