@use "../../global/" as *;

.p-fixed-header {
  background-color: $white;
  border-bottom: rem(1) solid $gray-500;
  transform: translateY(-150%);
  transition: transform $transition-duration;
}

.p-fixed-header.is-show {
  transform: translateY(0);
}

.p-fixed-header__content {
  display: flex;
  align-items: center;
}

.p-fixed-header__info {
  font-size: rem(12);
  font-weight: $bold;
  letter-spacing: calc(10 / 100 * 1em);
  line-height: rem(20);
  @include mq('md') {
    font-size: rem(16);
  }
}

.p-fixed-header__info.-font-small {
  font-size: rem(11);
  font-weight: $regular;
}

.p-fixed-header__nav {
  @include mq('md') {
    margin-left: auto;
    height: inherit;
  }
}

.p-fixed-header__menu-icon {
  width: rem(56);
  height: rem(56);
  position: relative;
  z-index: $layer-hamburger;
  margin-left: auto;
  @include mq('md') {
    margin-left: rem(80);
  }
}

.p-fixed-header__drawer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $layer-drawer;
  width: 100%;
  height: 100vh;
  padding: rem(8) 0 rem(44);
}