@use "../../global/" as *;

.c-input-select {
  display: inline-block;
  position: relative;
}

.c-input-select::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: rem(1);
  transform: translateY(-50%);
  width: rem(23);
  height: calc(100% - #{rem(2)});
  background-color: #F2F2F2;
  border-radius: 0 rem(5) rem(5) 0;
  pointer-events: none;
  z-index: 1;
}

.c-input-select::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: rem(8);
  width: rem(6);
  height: rem(6);
  border-right: rem(1) solid $black;
  border-bottom: rem(1) solid $black;
  transform: translateY(-50%) rotate(45deg) skew(-8deg, -8deg);
  pointer-events: none;
  z-index: 2;
}

.c-input-select select {
  background-color: $white;
	border: rem(1) solid $gray-500;
	border-radius: rem(5);
	font-size: rem(14);
  font-weight: $regular;
  line-height: rem(24);
	padding: rem(8) rem(56) rem(7) rem(19);
	width: 100%;
}

@-moz-document url-prefix() {
  .c-input-select select {
    padding: rem(8) rem(52) rem(7) rem(16);
  }
}