@use "../../global/" as *;

.c-button-deco-left {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: rem(11) rem(4);
  border-radius: rem(8);
  background-color: $white;
  color: $text;
  font-family: inherit;
  text-align: left;
  position: relative;
  @include mq('md') {
    padding: rem(4) rem(12) rem(4) 0;
    transition: transform 445ms;
  }
}

.c-button-deco-left.-size-large {
  padding: rem(2) rem(12) rem(2) rem(2);
  @include mq('md') {
    justify-content: center;
    padding: 0 rem(32);
  }
}

.c-button-deco-left:focus,
.c-button-deco-left:hover {
  @include mq('md') {
    transform: scale(0.85);
  }
}

.c-button-deco-left__deco {
  display: block;
  width: rem(32);
  position: relative;
  @include mq('md') {
    width: rem(64);
  }
}

.c-button-deco-left.-size-large .c-button-deco-left__deco {
  width: rem(116);
  @include mq('md') {
    width: rem(120);
  }
}

.c-button-deco-left__deco::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.c-button-deco-left__deco > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.c-button-deco-left__text {
  font-size: rem(12);
  font-weight: $bold;
  // letter-spacing: calc(10 / 100 * 1em);
  line-height: rem(24);
  line-height: 1.2;
  padding-left: rem(21);
  margin-left: rem(12);
  position: relative;
  @include mq('md') {
    font-size: rem(16);
    letter-spacing: 0;
    padding-left: rem(12);
    margin-left: rem(4);
    transition: border-color $transition-duration;
  }
}

.c-button-deco-left.-size-large .c-button-deco-left__text {
  font-size: rem(16);
  letter-spacing: calc(7 / 100 * 1em);
  line-height: rem(36);
  padding-left: rem(17);
  margin-left: rem(13);
  @include mq('md') {
    font-size: rem(32);
    letter-spacing: calc(30 / 100 * 1em);
    margin-left: rem(21);
  }
}

.c-button-deco-left__text::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: rem(1);
  height: rem(24);
  background-color: $text-thin;
  @include mq('md') {
    height: rem(40);
  }
}

.c-button-deco-left.-size-large .c-button-deco-left__text::before {
  height: rem(64);
}

.c-button-deco-left__bottom-right {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  border-top: rem(19) solid transparent;
  border-right: rem(19) solid $brand-accent;
  border-bottom: rem(19) solid $brand-accent;
  border-left: rem(19) solid transparent;
  border-radius: 0 0 rem(8) 0;
  @include mq('md') {
    border-top: rem(26) solid transparent;
    border-right: rem(26) solid $brand-accent;
    border-bottom: rem(26) solid $brand-accent;
    border-left: rem(26) solid transparent;
  }
}

.c-button-deco-left.-size-large .c-button-deco-left__bottom-right {
  border-top: rem(34) solid transparent;
  border-right: rem(34) solid $brand-accent;
  border-bottom: rem(34) solid $brand-accent;
  border-left: rem(34) solid transparent;
  border-radius: 0 0 rem(8) 0;
}

.c-button-deco-left__bottom-right::before {
  color: $white;
  content: '\f35a';
  font-family: 'Font Awesome 5 Free';
  font-size: rem(12);
  font-weight: 400;
  position: absolute;
  @include mq('md') {
    font-size: rem(16);
  }
}

.c-button-deco-left.-size-large .c-button-deco-left__bottom-right::before {
  font-size: rem(16);
}