@use "../global/" as *;

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="search"],
textarea {
  appearance: none;
	background-color: transparent;
	background-image: none;
	border: none;
	border-radius: 0;
	color: inherit;
	font-family: inherit;
	font-size: 1em;
	padding: 0.4em 0.8em;
	width: 100%;
}

input[type="text"]:focus,
input[type="tel"]:focus,
input[type="email"]:focus {
	box-shadow: none;
	outline: none;
}

input[type="search"]:focus {
	outline: none;
}

input[type="search"]:-webkit-autofill {
	transition: background-color 5000s ease-in-out 0s !important;
	-webkit-text-fill-color: #D1D1D1;
}

input[type="radio"] {
	position: absolute;
	margin: 0;
  opacity: 0;
}

input[type="checkbox"] {
  position: absolute;
  margin: 0;
  opacity: 0;
}

select {
	appearance: none;
	border: none;
	border-radius: 0;
	color: inherit;
	cursor: pointer;
	font-family: inherit;
	font-size: 1em;
	padding: 0.4em 0.8em;
	width: 100%;
}

select::-ms-expand {
	display: none;
}

select:focus {
  box-shadow: none;
  outline: none;
}