@use "../../global/" as *;

.c-input-date-select {
  display: inline-block;
  position: relative;
}

// .c-input-date-select::before {
//   content: "";
//   display: block;
//   position: absolute;
//   top: 50%;
//   right: rem(1);
//   transform: translateY(-50%);
//   width: rem(14);
//   height: calc(100% - #{rem(2)});
//   background-color: #F2F2F2;
//   border-radius: 0 rem(5) rem(5) 0;
//   pointer-events: none;
// }

// .c-input-date-select::after {
//   content: "";
//   display: block;
//   position: absolute;
//   top: 50%;
//   right: rem(4);
//   width: rem(5);
//   height: rem(5);
//   border-right: rem(1) solid $black;
//   border-bottom: rem(1) solid $black;
//   transform: translateY(-50%) rotate(45deg) skew(-8deg, -8deg);
//   pointer-events: none;
// }

.c-input-date-select select {
  background-color: $white;
	border: rem(1) solid $gray-500;
	border-radius: rem(5);
	font-size: rem(14);
  font-weight: $regular;
  line-height: rem(24);
	padding: rem(7) rem(101) rem(8) rem(12);
	width: 100%;
}

.c-input-date-select input {
  border: rem(1) solid $gray-500;
	border-radius: rem(5);
	font-size: rem(14);
  font-weight: $regular;
  letter-spacing: rem(1);
  line-height: rem(24);
	padding: rem(7) rem(12);
}

// @-moz-document url-prefix() {
//   .c-input-date-select select {
//     padding: rem(8) rem(21) rem(7) rem(7);
//   }
// }