@use "../../global/" as *;

// ----------
// p-faq-search
// ----------
.p-faq-search {
  position: relative;
}

.p-faq-search__round-text-deco {
  display: block;
  position: relative;
  left: rem(-22);
  width: rem(140);
  height: rem(140);
  background-image: url(../img/resident/resident-deco.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @include mq("md") {
    width: rem(168);
    height: rem(168);
    left: rem(-70);
  }
}

.p-faq-search__content {
  background-color: $brand-main;
  border-radius: rem(8);
  padding: rem(24) rem(24) rem(40);
  margin-top: rem(-92);
  position: relative;
  z-index: $layer-default;
  @include mq("md") {
    padding: rem(32) rem(64) rem(64);
    margin-top: rem(-99);
  }
}

.p-faq-search__badge {
  position: absolute;
  top: rem(-21);
  left: rem(9);
  z-index: $layer-up;
  min-width: rem(72);
  min-height: rem(72);
  background-color: $brand-accent;
  border-radius: 50%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include mq("md") {
    min-width: rem(88);
    min-height: rem(83);
    top: rem(-33);
    left: rem(-32);
  }
}

.p-faq-search__badge > span {
  display: block;
  color: $white;
  font-size: rem(20);
  font-weight: $bold;
  @include mq("md") {
    font-size: rem(24);
  }
}

.p-faq-search__badge > span:nth-child(1) {
  font-size: rem(10);
  letter-spacing: calc(10 / 100 * 1em);
  @include mq("md") {
    font-size: rem(11);
  }
}

.p-faq-search__title {
  color: $white;
  font-size: rem(16);
  font-weight: $bold;
  letter-spacing: calc(30 / 100 * 1em);
  text-align: center;
  @include mq("md") {
    font-size: rem(20);
    text-align: left;
  }
}

.p-faq-search__form-wrapper {
  margin-top: rem(20);
  @include mq("md") {
    margin-top: rem(16);
  }
}

.p-faq-search__form {
  position: relative;
  background-image: url(../img/resident/search.svg) !important;
  background-repeat: no-repeat;
  background-position: left rem(12) center;
  background-size: rem(24) rem(24);
}


.p-faq-search__form input[type="search"] {
  border-radius: rem(8);
  box-shadow: rem(-6) rem(-6) rem(12) 0 rgba(#0062ba, 1) inset,
    rem(8) rem(8) rem(12) 0 rgba(#00488a, 1) inset;
  color: $gray-500;
  font-size: rem(16);
  font-weight: $regular;
  letter-spacing: calc(10 / 100 * 1em);
  line-height: rem(36);
  padding: rem(10) rem(11) rem(10) rem(44);
  @include mq("md") {
    font-size: rem(14);
  }
}

.p-faq-search__form input[type="search"]::placeholder {
  color: $gray-500;
}

.p-faq-search__link-induction {
  color: $white;
  font-size: rem(12);
  font-weight: $bold;
  letter-spacing: calc(30 / 100 * 1em);
  text-align: center;
  margin-top: rem(24);
  @include mq("md") {
    font-size: rem(14);
    text-align: left;
    margin-top: rem(19);
  }
}

.p-faq-search__buttons {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: rem(24);
  margin: rem(18) 0 0;
  @include mq("md") {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 0;
    column-gap: rem(31);
    margin: rem(13) 0 rem(-8);
  }
}

.p-faq-search__button {
  box-shadow: rem(-8) rem(-4) rem(8) 0 rgba(#0062ba, 1),
    rem(4) rem(4) rem(8) 0 rgba(#00488a, 1);
}

.p-faq-search__dropdown {
  width: calc(291 / 327 * 100%);
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: $layer-up;
}
