@use "../../global/" as *;

.c-button-square {
  display: inline-block;
  padding: rem(8) rem(14) rem(10);
  background-color: $brand-thin;
  border-radius: rem(8);
  box-shadow: rem(-8) rem(-8) rem(12) rgba($white, 0.4), rem(8) rem(8) rem(12) rgba(#D9CFCF, 0.7);
  position: relative;
  @include mq('md') {
    padding: rem(10) rem(14) rem(20);
    min-height: rem(218);
    transform: scale(1);
    transition: transform 445ms;
  }
}

.c-button-square:focus,
.c-button-square:hover {
  @include mq('md') {
    transform: scale(0.85);
  }
}

.c-button-square__logo {
  width: rem(64);
  margin: rem(-10) auto 0;
  border-radius: 50%;
  background-color: $white;
  position: relative;
  @include mq('md') {
    width: rem(120);
    margin-top: rem(-5);
  }
}

.c-button-square__logo::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.c-button-square__logo > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.c-button-square__title-en {
  display: inline-block;
  color: $brand-accent;
  font-family: $font-family-sub-medium;
  font-size: rem(18);
  font-weight: $medium;
  line-height: 1;
  text-transform: capitalize;
  transform: rotate(-18deg);
  transform-origin: bottom center;
  @include mq('md') {
    font-size: rem(24);
  }
}

.c-button-square__title-ja {
  display: block;
  color: $text;
  font-size: rem(12);
  font-weight: $bold;
  letter-spacing: calc(20 / 100 * 1em);
  text-indent: calc(20 / 100 * 1em);
  line-height: rem(16);
  text-align: center;
  @include mq('md') {
    font-size: rem(16);
    margin-top: rem(16);
  }
}

.c-button-square__bottom-right {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  border-top: rem(22) solid transparent;
  border-right: rem(22) solid $brand-accent;
  border-bottom: rem(22) solid $brand-accent;
  border-left: rem(22) solid transparent;
  border-radius: 0 0 rem(8) 0;
  @include mq('md') {
    border-top: rem(27) solid transparent;
    border-right: rem(27) solid $brand-accent;
    border-bottom: rem(27) solid $brand-accent;
    border-left: rem(27) solid transparent;
  }
}

.c-button-square__bottom-right::before {
  color: $white;
  content: '\f35a';
  font-family: 'Font Awesome 5 Free';
  font-size: rem(12);
  font-weight: 400;
  position: absolute;
  @include mq('md') {
    font-size: rem(16);
  }
}