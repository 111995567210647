@use "../../global/" as *;

.c-hamburger {
  border: rem(2) solid $brand-accent;
  border-radius: 50%;
  background-color: $brand-accent;
  @include mq('md') {
    transition: background-color $transition-duration;
  }
}

.c-hamburger:focus,
.c-hamburger:hover {
  @include mq('md') {
    background-color: $white;
  }
}

.c-hamburger__bars {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: rem(32);
  height: rem(12);
  margin: auto;
}

.c-hamburger__bar {
  display: block;
  position: absolute;
  left: 0;
  height: rem(2);
  background-color: $white;
  @include mq('md') {
    transition: background-color $transition-duration;
  }
}

.c-hamburger:focus .c-hamburger__bar,
.c-hamburger:hover .c-hamburger__bar {
  @include mq('md') {
    background-color: $brand-accent;
  }
}

.c-hamburger__bar:nth-child(1) {
  top: 0;
  width: 100%;
  transform: rotate(0);
  transition: transform $transition-duration;
}

.c-hamburger.is-open .c-hamburger__bar:nth-child(1) {
  top: rem(5);
  transform: rotate(30deg);
}

.c-hamburger__bar:nth-child(2) {
  top: rem(10);
  width: 75%;
  transform: rotate(0);
  transition: width $transition-duration, transform $transition-duration;
}

.c-hamburger.is-open .c-hamburger__bar:nth-child(2) {
  top: rem(5);
  width: 100%;
  transform: rotate(-30deg);
}