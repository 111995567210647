@use "../../global/" as *;

.c-banner {
  display: flex;
  align-items: center;
  background-color: $white;
  padding: rem(8) rem(33) rem(8) rem(23);
  min-height: rem(44);
  border: rem(1) solid $gray-500;
  border-radius: rem(8);
  position: relative;
  @include mq('md') {
    padding: rem(18) rem(61) rem(18) rem(41);
    @include mq('md') {
      transition: opacity $transition-duration;
    }
  }
}

.c-banner:focus,
.c-banner:hover {
  @include mq('md') {
    opacity: $opacity;
  }
}

.c-banner__bottom-right {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  border-top: rem(15) solid transparent;
  border-right: rem(15) solid $gray-100;
  border-bottom: rem(15) solid $gray-100;
  border-left: rem(15) solid transparent;
  border-radius: 0 0 rem(8) 0;
  @include mq('md') {
    border-top: rem(27) solid transparent;
    border-right: rem(27) solid $gray-100;
    border-bottom: rem(27) solid $gray-100;
    border-left: rem(27) solid transparent;
  }
}

.c-banner__bottom-right::before {
  color: $gray-500;
  content: '\f35d';
  font-family: 'Font Awesome 5 Free';
  font-size: rem(9);
  font-weight: 900;
  position: absolute;
  @include mq('md') {
    font-size: rem(16);
  }
}