@use "../../global/" as *;

.c-links-area {
  background-color: $white;
  border-radius: rem(8);
  padding: rem(32) rem(24);
  margin-top: rem(24);
  @include mq('md') {
    display: flex;
    margin-top: rem(32);
  }
}

.c-links-area__item-wrapper {
  @include mq('md') {
    // flex: 0 0 rem(279);
  }
}

.c-links-area__item-wrapper:nth-child(n + 2) {
  margin: rem(24) 0 0;
  @include mq('md') {
    margin: 0 0 0 rem(210);
  }
}

.c-links-area__item {
  font-size: rem(16);
  font-weight: $bold;
  letter-spacing: calc(15 / 100 * 1em);
  line-height: rem(32);
  padding-left: rem(24);
  position: relative;
}

.c-links-area__item::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: rem(8);
  height: rem(24);
  border-radius: rem(4);
  background-color: $brand-accent;
}

.c-links-area__text {
}

.c-links-area__link {
  color: inherit;
  display: flex;
  align-items: baseline;
}

.c-links-area__icon {
  width: rem(14);
  margin-left: rem(4);
  transform: translateY(0.1rem);
}

.c-links-area__item:nth-child(n + 2) {
  margin-top: rem(24);
}

.c-links-area__description {
  font-size: rem(14);
  font-weight: $bold;
  letter-spacing: calc(10 / 100 * 1em);
  line-height: rem(36);
  margin-top: rem(25);
  @include mq('md') {
    margin-top: rem(16);
  }
}