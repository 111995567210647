@use "../../global/" as *;

// ----------
// p-faq-nav
// ----------
.p-faq-nav__item {
  font-size: rem(12);
  font-weight: $medium;
  line-height: rem(18);
  @include mq('md') {
    font-size: rem(14);
  }
}

.p-faq-nav__link {
  display: block;
  padding: rem(16) rem(20) rem(8) 0;
  color: $white;
  border-bottom: rem(1) solid $gray-300;
  position: relative;
}

.p-faq-nav__link::after {
  color: $white;
  content: '\f358';
  font-family: 'Font Awesome 5 Free';
  font-size: rem(12);
  font-weight: 400;
  position: absolute;
  right: 0;
  @include mq('md') {
    font-size: rem(14);
  }
}