@use "../../global/" as *;

// ----------
// p-faq-drawer
// ----------
.p-faq-drawer {
  background-color: $brand-sub;
  transform: translateX(105%);
  transition: transform $transition-duration;
}

.p-faq-drawer.is-open {
  transform: translateX(0);
}

.p-faq-drawer__scroll {
  width: 100%;
  height: inherit;
  padding: rem(120) 0;
  overflow-y: auto;
}

.p-faq-drawer__inner {
  width: 100%;
  padding: 0 rem(strip-unit($padding-sp));
  height: 100%;
  @include mq('md') {
    padding: 0 rem(strip-unit($padding-pc));
  }
}

.p-faq-drawer__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.p-faq-drawer__button {
  margin-top: rem(40);
}