@use "../../global/" as *;

.l-fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $layer-header;
  width: 100%;
  height: rem(strip-unit($fixed-header-height));
}

.l-fixed-header__inner {
  width: 100%;
  padding: 0 rem(strip-unit($padding-sp));
  height: inherit;
  @include mq('md') {
    padding: 0 rem(48) 0 rem(88);
  }
}