@use "../../global/" as *;

.p-pagination {
  display: flex;
  justify-content: center;
}

.p-pagination__pager {
  display: inline-block;
  background-color: $white;
  border: rem(1) solid $gray-500;
  color: $black;
  font-family: 'Roboto', sans-serif;
  font-size: rem(18);
  font-weight: $regular;
  letter-spacing: calc(10 / 100 * 1em);
  text-indent: calc(10 / 100 * 1em);
  line-height: 1;
  text-align: center;
  width: rem(40);
  padding: rem(10) 0;
}

.p-pagination__pager.is-current {
  background-color: $text;
  color: $white;
}

.p-pagination > a {
  @include mq('md') {
    transition: background-color $transition-duration, color $transition-duration;
  }
}

.p-pagination > a:focus,
.p-pagination > a:hover {
  @include mq('md') {
    background-color: $text;
    color: $white;
  }
}

.p-pagination__dots {
  display: inline-block;
  margin: 0 rem(15);
}