@use "../../global/" as *;

// ----------
// l-faq-drawer-overlay
// ----------
.l-faq-drawer-overlay {
  position: fixed;
  top: 0;
  right: 0;
  z-index: $layer-faq-drawer-overlay;
  width: 100%;
  height: 100vh;
}