@use "../../global/" as *;

.c-input-checkbox label {
  display: inline-block;
  position: relative;
}

.c-input-checkbox input[type="checkbox"] {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: rem(18);
  height: rem(18);
}

.c-input-checkbox input[type="checkbox"] + span {
  display: inline-block;
  padding-left: rem(30);
  font-size: rem(14);
  font-weight: $regular;
  line-height: rem(20);
}

.c-input-checkbox.-text-highlighted input[type="checkbox"] + span {
  color: $brand-accent;
  font-weight: $bold;
}

.c-input-checkbox input[type="checkbox"] + span::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: rem(18);
  height: rem(18);
  border: rem(2) solid $gray-500;
  border-radius: rem(2);
  transition: border-color $transition-duration;
}

.c-input-checkbox input[type="checkbox"]:checked + span::before {
  border-color: $brand-accent;
}

.c-input-checkbox input[type="checkbox"] + span::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: rem(4);
  width: rem(10);
  height: rem(6);
  border-bottom: rem(2) solid $brand-accent;
  border-left: rem(2) solid $brand-accent;
  transform: translateY(-50%) rotate(-45deg);
  opacity: 0;
  transition: opacity $transition-duration;
}

.c-input-checkbox input[type="checkbox"]:checked + span::after {
  opacity: 1;
}