@use "../../global/" as *;

// ----------
// p-faq-dropdown
// ----------
.p-faq-dropdown {
  background-color: $brand-sub;
  display: none;
}

.p-faq-dropdown.is-open {
  display: block;
}

.p-faq-dropdown__scroll {
  width: 100%;
  height: inherit;
  padding: rem(8) 0 rem(24);
  overflow-y: auto;
}

.p-faq-dropdown__inner {
  width: 100%;
  padding: 0 rem(strip-unit($padding-sp));
  height: 100%;
  @include mq('md') {
    padding: 0 rem(strip-unit($padding-pc));
  }
}

.p-faq-dropdown__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.p-faq-dropdown__button {
  margin-top: rem(40);
}