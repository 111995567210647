@use "../../global/" as *;

// ----------
// .l-top
// ----------
.l-top__topics {
  margin-top: rem(140);
  @include mq('md') {
    margin-top: rem(124);
  }
}

.l-top__philosophy {
  margin-top: rem(49);
  @include mq('md') {
    margin-top: rem(129);
  }
}

.l-top__guide {
  margin-top: rem(64);
  @include mq('md') {
    margin-top: rem(128);
  }
}

.l-top__company-info {
  margin-top: rem(64);
  @include mq('md') {
    margin-top: rem(128);
  }
}

.l-top__banner {
  margin-top: rem(32);
  @include mq('md') {
    margin-top: rem(128);
  }
}

// ----------
// .l-top-mv
// ----------
.l-top-mv {
  padding: rem(140) 0 rem(48);
  @include mq('md') {
    padding: rem(201) 0 rem(115);
  }
}

// ----------
// .l-top-guide
// ----------
.l-top-guide {
  padding: rem(64) 0;
  @include mq('md') {
    padding: rem(128) 0 rem(118);
  }
}

// ----------
// .l-top-banner
// ----------
.l-top-banner {
  padding: 0 0 rem(48);
  @include mq('md') {
    padding: rem(64) 0;
  }
}