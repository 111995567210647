@use "../../global/" as *;

.l-hamburger {
  position: absolute;
  top: rem(44);
  right: rem(24);
  z-index: $layer-hamburger;
  width: rem(56);
  height: rem(56);
  @include mq('md') {
    top: rem(20);
    right: rem(48);
    width: rem(64);
    height: rem(64);
  }
}