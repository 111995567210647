@use "../../global/" as *;

.c-info-wrapper {
  &:not(:first-child) {
    margin-top: rem(40);
  }
}

.c-info__title {
  font-size: rem(16);
  font-weight: $bold;
  letter-spacing: calc(15 / 100 * 1em);
  line-height: rem(24);
  padding-left: rem(24);
  position: relative;
  margin-bottom: rem(24);
  @include mq("md") {
    font-size: rem(24);
    line-height: rem(32);
  }
}

.c-info__title::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: rem(8);
  height: rem(24);
  background-color: $brand-accent;
  border-radius: rem(4);
}

.c-info__text {
  display: block;
  font-size: rem(14);
  font-weight: $bold;
  letter-spacing: calc(10 / 100 * 1em);
  text-indent: calc(10 / 100 * 1em);
  line-height: rem(24);
}

.c-info__text.-tel-layout {
  font-size: rem(18);
  padding: rem(16);
  background: #ffffff;
  border: 3px solid #cd5954;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.1em;
  color: #cd5954;
  margin-bottom: rem(16);
  @include mq("md") {
    font-size: rem(14);
    color: inherit;
    justify-content: flex-start;
    background: none;
    border-width: 0;
    padding: rem(0);
    margin-bottom: rem(0);
  }
}

.c-info__text.-font-small {
  font-size: rem(13);
  font-weight: $regular;
  margin-top: rem(8);
  @include mq("md") {
    margin-top: rem(14);
    font-size: rem(14);
  }
}
