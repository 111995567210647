@use "../../global/" as *;

.p-footer-nav__title {
  color: $white;
  font-family: 'Avenir Next', 'Arial', sans-serif;
  font-size: rem(14);
  font-weight: $extra-bold;
  letter-spacing: calc(20 / 100 * 1em);
  line-height: rem(20);
  text-transform: uppercase;
  padding-bottom: rem(8);
  border-bottom: rem(1) solid #C4C4C4;
}

.p-footer-nav__items {
  margin-top: rem(16);
}

.p-footer-nav__item {
  font-size: rem(12);
  font-weight: $bold;
  letter-spacing: calc(10 / 100 * 1em);
  text-indent: calc(10 / 100 * 1em);
  line-height: 2;
}

.p-footer-nav__link {
  display: block;
  color: $white;
  @include mq('md') {
    transition: opacity $transition-duration;
  }
}

.p-footer-nav__link:focus,
.p-footer-nav__link:hover {
  @include mq('md') {
    opacity: $opacity;
  }
}