@use "../../global/" as *;

.l-drawer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $layer-drawer;
  width: 100%;
  height: 100vh;
  padding: rem(44) 0 0;
  @include mq('md') {
    padding: rem(20) 0 0;
  }
}