@use "../../global/" as *;

.p-header {
  background-color: $white;
  border-radius: rem(48);
  @include mq('md') {
    box-shadow: rem(-8) rem(-8) rem(12) 0 rgba($white, 0.4), rem(8) rem(8) rem(12) 0 rgba(#C4CAD1, 0.7);
  }
}

.p-header__content {
  display: flex;
  align-items: center;
}

.p-header__logo {
  width: 100%;
  @include mq('md') {
    width: rem(200);
  }
}

.p-header__nav {
  @include mq('md') {
    margin-left: auto;
    height: inherit;
  }
}