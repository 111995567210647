@use "../../global/" as *;

.p-drawer-nav-link {
  display: flex;
  flex-direction: column;
  padding: rem(16) rem(32);
  color: $white;
  position: relative;
  @include mq("md") {
    padding: rem(16) 0 rem(16) rem(64);
    transition: opacity $transition-duration;
  }
}

.p-drawer-nav-link:focus,
.p-drawer-nav-link:hover {
  @include mq('md') {
    opacity: $opacity;
  }
}

.p-drawer-nav-link::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: rem(16);
  height: rem(4);
  background-color: $white;
  border-radius: rem(1);
  @include mq("md") {
    width: rem(48);
  }
}

.p-drawer-nav-link__ja,
.p-drawer-nav-link__en {
  display: block;
}

.p-drawer-nav-link__ja {
  font-size: rem(20);
  font-weight: $bold;
  letter-spacing: calc(10 / 100 * 1em);
  text-indent: calc(10 / 100 * 1em);
  line-height: 1;
  @include mq("md") {
    font-size: rem(24);
  }
}

.p-drawer-nav-link__en {
  opacity: 0.2;
  font-family: $font-family-sub-medium;
  font-size: rem(13);
  font-weight: $medium;
  line-height: rem(16);
  text-transform: capitalize;
  margin-top: rem(4);
  @include mq("md") {
    font-size: rem(16);
  }
}