@use "../../global/" as *;

.c-notice {
  margin-top: rem(16);
  font-size: rem(12);
  line-height: rem(28);
  font-weight: $bold;
  color: $brand-accent;
  @include mq('md') {
    letter-spacing: calc(5 / 100 * 1em);
  }
}