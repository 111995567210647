@use "../global/" as *;

.wp-pagenavi {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.wp-pagenavi a.page,
.wp-pagenavi a.first,
.wp-pagenavi a.last,
.wp-pagenavi span.current {
  display: inline-block;
  background-color: $white;
  border: rem(1) solid $gray-500;
  color: $black;
  font-family: 'Roboto', sans-serif;
  font-size: rem(18);
  font-weight: $regular;
  letter-spacing: calc(10 / 100 * 1em);
  text-indent: calc(10 / 100 * 1em);
  line-height: 1;
  text-align: center;
  width: rem(40);
  padding: rem(10) 0;
}

.wp-pagenavi span.current {
  background-color: $text;
  color: $white;
}

.wp-pagenavi a {
  @include mq('md') {
    transition: background-color $transition-duration, color $transition-duration;
  }
}

.wp-pagenavi a:focus,
.wp-pagenavi a:hover {
  @include mq('md') {
    background-color: $text;
    color: $white;
  }
}

.wp-pagenavi span.extend {
  display: inline-block;
  margin: 0 rem(15);
}