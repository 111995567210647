@use "../../global/" as *;

// ----------
// .top-mv
// ----------
.top-mv {
  background-color: $brand-thin;
}

.top-mv__inner {
  width: 100%;
  max-width: rem(327 + strip-unit($padding-sp) * 2);
  margin: 0 auto;
  padding: 0 rem(strip-unit($padding-sp));
  @include mq('md') {
    max-width: rem(strip-unit($inner-pc) + strip-unit($padding-pc) * 2);
    padding: 0 rem(strip-unit($padding-pc));
  }
}

.top-mv__content {
  @include mq("md") {
    display: flex;
    align-items: center;
  }
}

.top-mv__text-wrapper {
  font-weight: $bold;
  width: calc(100% - #{rem(48)});
  margin: 0 auto;
  @include mq('md') {
    width: auto;
    margin: 0;
    flex: 0 1 auto;
  }
}

.top-mv__title {
  font-size: rem(48);
  letter-spacing: calc(36.5 / 100 * 1em);
  line-height: rem(64);
  @include mq('md') {
    font-size: rem(56);
    letter-spacing: calc(30 / 100 * 1em);
    line-height: rem(72);
  }
}

.top-mv__lead {
  font-size: rem(20);
  letter-spacing: calc(30 / 100 * 1em);
  margin-top: rem(12);
  @include mq('md') {
    margin-top: rem(34);
  }
}

.top-mv__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: rem(16);
  row-gap: rem(20);
  margin: rem(37) 0 0;
  @include mq('md') {
    column-gap: rem(48);
    row-gap: rem(48);
    margin: 0 0 0 auto;
    flex: 0 0 calc(484 / #{strip-unit($inner-pc)} * 100%);
  }
}

// ----------
// .top-topics
// ----------
.top-topics__content {
  position: relative;
}

.top-topics__deco {
  width: rem(96);
  position: absolute;
  top: rem(-25);
  right: rem(strip-unit($padding-sp));
  @include mq('md') {
    width: rem(116);
    top: rem(-32);
    right: rem(strip-unit($padding-pc));
  }
}

.top-topics__deco::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.top-topics__deco > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.top-topics__head {
  display: flex;
  align-items: flex-end;
}

.top-topics__title {
  font-size: rem(20);
  font-weight: $bold;
  letter-spacing: calc(30 / 100 * 1em);
  line-height: 1.2;
}

.top-topics__archive-link-wrapper {
  margin-left: rem(10);
}

.top-topics__archive-link {
  color: $brand-accent;
  font-size: rem(12);
  font-weight: $bold;
  letter-spacing: calc(20 / 100 * 1em);
  text-indent: calc(20 / 100 * 1em);
  line-height: rem(20);
  border-bottom: rem(4) solid $brand-accent;
  @include mq('md') {
    transition: opacity $transition-duration;
  }
}

.top-topics__archive-link:focus,
.top-topics__archive-link:hover {
  @include mq('md') {
    opacity: $opacity;
  }
}

.top-topics__items {
  border: rem(4) solid $brand-accent;
  border-radius: rem(8);
  padding: rem(0) rem(20) rem(5);
  margin-top: rem(18);
  @include mq('md') {
    padding: rem(5) rem(44) rem(12);
    margin-top: rem(18);
  }
}

.top-topics__item:nth-child(n + 2) {
  border-top: rem(1) solid $gray-500;
}

// ----------
// .top-philosophy
// ----------
.top-philosophy__inner {
  width: 100%;
  max-width: rem(strip-unit($inner-sp) + strip-unit($padding-sp) * 2);
  margin: 0 auto;
  padding: 0 rem(strip-unit($padding-sp));
  @include mq("md") {
    max-width: rem(1220 + strip-unit($padding-pc));
    max-width: calc(50% + #{rem(500)} + #{rem(strip-unit($padding-pc))});
    margin: 0 0 0 auto;
    padding: 0 0 0 rem(strip-unit($padding-pc));
  }
}

.top-philosophy__content {
  display: flex;
  flex-direction: column;
  @include mq('md') {
    flex-direction: row;
    align-items: center;
  }
}

.top-philosophy__text-wrapper {
  display: contents;
  @include mq('md') {
    display: block;
    flex: 0 1 calc(590 / 1220 * 100%);
  }
}

.top-philosophy__lead {
  font-size: rem(20);
  font-weight: $bold;
  letter-spacing: calc(10 / 100 * 1em);
  text-indent: calc(10 / 100 * 1em);
  line-height: rem(40);
  order: 1;
  @include mq('md') {
    font-size: rem(40);
    letter-spacing: calc(14 / 100 * 1em);
    line-height: rem(72);
    order: initial;
  }
}

.top-philosophy__description {
  font-size: rem(16);
  font-weight: $bold;
  letter-spacing: calc(5 / 100 * 1em);
  line-height: rem(32);
  order: 3;
  margin-top: rem(25);
  @include mq('md') {
    letter-spacing: calc(10 / 100 * 1em);
    line-height: rem(40);
    order: initial;
  }
}

.top-philosophy__image {
  position: relative;
  order: 2;
  margin: rem(22) 0 0;
  @include mq('md') {
    flex: 0 0 calc(540 / 1220 * 100%);
    margin: 0 0 0 auto;
  }
}

.top-philosophy__image::before {
  content: "";
  display: block;
  padding-top: calc(185 / 327 * 100%);
  @include mq('md') {
    padding-top: calc(612 / 540 * 100%);
  }
}

.top-philosophy__image > picture > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.top-philosophy__deco {
  width: rem(119);
  position: absolute;
  top: rem(-73);
  right: rem(-13);
  @include mq('md') {
    width: rem(240);
    top: auto;
    right: auto;
    bottom: rem(-21);
    left: rem(-174);
  }
}

.top-philosophy__deco::before {
  content: "";
  display: block;
  padding-top: calc(110 / 119 * 100%);
}

.top-philosophy__deco > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

// ----------
// .top-guide
// ----------
.top-guide {
  position: relative;
}

.top-guide::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: $layer-background;
  width: 100%;
  max-width: 100%;
  height: 100%;
  background-color: $brand-thin;
  @include mq('md') {
    max-width: calc(100% - #{rem(strip-unit($padding-pc))} * 2);
    border-radius: rem(8);
  }
}

.top-guide__inner {
  width: 100%;
  max-width: rem(327 + strip-unit($padding-sp) * 2);
  margin: 0 auto;
  padding: 0 rem(strip-unit($padding-sp));
  @include mq("md") {
    max-width: rem(1016 + strip-unit($padding-pc) * 2);
    padding: 0 rem(strip-unit($padding-pc));
  }
}

.top-guide__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: rem(16);
  row-gap: rem(22);
  @include mq('md') {
    grid-template-columns: repeat(4, 1fr);
    column-gap: rem(48);
    row-gap: rem(36);
  }
}

.top-guide__description {
  font-size: rem(14);
  font-weight: $bold;
  letter-spacing: calc(21 / 100 * 1em);
  line-height: rem(36);
  margin: rem(24) 0 0;
  @include mq('md') {
    width: 100%;
    max-width: rem(720);
    margin: rem(48) auto 0;
  }
}

// ----------
// .top-company-info
// ----------
.top-company-info__content {
  @include mq('md') {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.top-company-info__image {
  width: 100%;
  position: relative;
  @include mq('md') {
    width: auto;
    flex: 0 0 calc(362 / #{strip-unit($inner-pc)} * 100%);
    transform: translateY(-50px);
  }
}

.top-company-info__image::before {
  content: "";
  display: block;
  padding-top: calc(67 / 327 * 100%);
  @include mq('md') {
    // padding-top: calc(300 / 192 * 100%);
  }
}

.top-company-info__image > picture > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.top-company-info__text-wrapper {
  margin: rem(40) 0 0;
  @include mq('md') {
    margin: 0 0 0 rem(100);
    flex: 0 1 calc(609 / #{strip-unit($inner-pc)} * 100%);
  }
}

.top-company-info__title {
  font-size: rem(24);
  font-weight: $bold;
  letter-spacing: calc(20 / 100 * 1em);
  line-height: rem(32);
  @include mq('md') {
    font-size: rem(32);
  }
}

.top-company-info__description {
  font-size: rem(14);
  font-weight: $bold;
  letter-spacing: calc(10 / 100 * 1em);
  text-indent: calc(10 / 100 * 1em);
  line-height: rem(36);
  margin-top: rem(16);
  @include mq('md') {
    margin-top: rem(32);
  }
}

.top-company-info__link-wrapper {
  margin-top: rem(20);
  text-align: right;
  @include mq('md') {
    margin-top: rem(52);
  }
}

.top-company-info__link {
  display: inline-block;
  padding-bottom: rem(4);
  color: $brand-accent;
  font-size: rem(12);
  font-weight: $bold;
  letter-spacing: calc(20 / 100 * 1em);
  text-indent: calc(20 / 100 * 1em);
  border-bottom: rem(4) solid $brand-accent;
  @include mq('md') {
    transition: opacity $transition-duration;
  }
}

.top-company-info__link:focus,
.top-company-info__link:hover {
  @include mq('md') {
    opacity: $opacity;
  }
}

// ----------
// company-banner
// ----------
.top-banner {
  @include mq('md') {
    border-top: rem(4) dotted $brand-main;
  }
}

.top-banner__inner {
  width: 100%;
  max-width: rem(685 + strip-unit($padding-pc) * 2);
  margin: 0 auto;
  padding: 0 rem(strip-unit($padding-sp));
  @include mq("md") {
    padding: 0 rem(strip-unit($padding-pc));
  }
}

.top-banner__items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: rem(16);
  row-gap: rem(25);
  @include mq('md') {
    // grid-template-columns: repeat(4, 1fr);
    column-gap: rem(48);
    row-gap: rem(32);
  }
}