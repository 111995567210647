@use "../../global/" as *;

.c-button-submit {
	background-color: $white;
	border: rem(3) solid $brand-accent;
	border-radius: rem(8);
	color: $brand-accent;
	display: inline-block;
  font-family: inherit;
	font-size: rem(14);
  font-weight: $bold;
  letter-spacing: calc(10 / 100 * 1em);
  text-indent: calc(10 / 100 * 1em);
  line-height: rem(20);
	padding: rem(23) rem(13);
  min-width: rem(280);
  position: relative;
  transition: background-color $transition-duration, color $transition-duration;
  @include mq('md') {
    min-width: rem(243);
  }
}

.c-button-submit:focus,
.c-button-submit:hover {
  @include mq('md') {
    background-color: $brand-accent;
    color: $white;
  }
}

.c-button-submit::after {
  color: $brand-accent;
  content: '\f35a';
  font-family: 'Font Awesome 5 Free';
  font-size: rem(20);
  font-weight: 900;
  position: absolute;
  top: 50%;
  right: rem(12);
  transform: translateY(-50%);
  transition: color $transition-duration;
}

.c-button-submit:focus::after,
.c-button-submit:hover::after {
  @include mq('md') {
    color: $white;
  }
}