@use "../../global/" as *;


.c-input-radio {
  font-size: 0;
}

.c-input-radio label {
  display: inline-block;
  position: relative;
}
.c-input-radio .wpcf7-radio {
  display: flex;
  flex-direction: column;
}

.c-input-radio .wpcf7-list-item {
  &:not(:first-child) {
    margin-top: rem(16);
  }
}

.c-input-radio input[type="radio"] {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: rem(20);
  height: rem(20);
}

.c-input-radio input[type="radio"] + span {
  display: inline-block;
  padding-left: rem(32);
  font-size: rem(14);
  font-weight: $regular;
}

.c-input-radio input[type="radio"] + span::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: rem(20);
  height: rem(20);
  transform: translateY(-50%);
  background-color: $brand-thin;
  border-radius: 50%;
}

.c-input-radio input[type="radio"] + span::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: rem(3);
  width: rem(14);
  height: rem(14);
  transform: translateY(-50%);
  background-color: $brand-main;
  border-radius: 50%;
  opacity: 0;
  transition: opacity $transition-duration;
}

.c-input-radio input[type="radio"]:checked + span::after {
  opacity: 1;
}

.custom-wpcf7c-confirmed .c-input-radio .wpcf7-list-item-label {
  display: none;
  font-size: 0;
}

.custom-wpcf7c-confirmed .c-input-radio .wpcf7c-conf-hidden + .wpcf7-list-item-label{
  display: inline-block;
  padding-left: rem(32);
  font-size: rem(14);
  font-weight: $regular;
}