@use "../../global/" as *;

.l-inner {
  width: 100%;
  max-width: rem(strip-unit($inner-sp) + strip-unit($padding-sp) * 2);
  margin: 0 auto;
  padding: 0 rem(strip-unit($padding-sp));
  @include mq("md") {
    max-width: rem(strip-unit($inner-pc) + strip-unit($padding-pc) * 2);
    padding: 0 rem(strip-unit($padding-pc));
  }
}