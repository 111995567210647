@use "../../global/" as *;

.p-info {
  display: block;
  padding: rem(23) 0 rem(15);
  @include mq('md') {
    display: flex;
    align-items: flex-start;
    padding: rem(24) 0 rem(15);
    transition: box-shadow $transition-duration;
  }
}

.p-info:focus,
.p-info:hover {
  @include mq('md') {
    box-shadow: 0 rem(-5) rem(3) rem(-3) rgba($black, 0.3), 0 rem(5) rem(3) rem(-3) rgba($black, 0.3);
  }
}

.p-info:first-child:focus,
.p-info:first-child:hover {
  @include mq('md') {
    box-shadow: 0 rem(5) rem(3) rem(-3) rgba($black, 0.3);
  }
}

.p-info:last-child:focus,
.p-info:last-child:hover {
  @include mq('md') {
    box-shadow: 0 rem(-5) rem(3) rem(-3) rgba($black, 0.3);
  }
}

.p-info__meta {
  display: flex;
  align-items: center;
  @include mq('md') {
    flex: 0 0 auto;
  }
}

.p-info__published {
  color: $text;
  font-size: rem(12);
  font-weight: $regular;
  letter-spacing: calc(7 / 100 * 1em);
  text-indent: calc(7 / 100 * 1em);
  line-height: rem(20);
  // padding-top: rem(2);
}

.p-info__category-wrapper {
  margin-left: rem(8);
  display: flex;
}

.p-info__category {
  display: inline-block;
  padding: rem(4) rem(8) rem(5);
  background-color: $brand-accent;
  color: $white;
  font-size: rem(11);
  font-weight: $bold;
  letter-spacing: calc(7 / 100 * 1em);
  line-height: 1;
}

.p-info__title {
  color: $text;
  font-size: rem(14);
  font-weight: $bold;
  letter-spacing: calc(7 / 100 * 1em);
  text-indent: calc(7 / 100 * 1em);
  line-height: rem(24);
  margin: rem(8) 0 0;
  @include mq('md') {
    flex: 0 1 100%;
    margin: 0 0 0 rem(24);
  }
}