@charset "UTF-8";
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* webkit specific styles */
input[type=color]::-webkit-color-swatch {
  border: none;
}

input[type=color]::-webkit-color-swatch-wrapper {
  padding: 0;
}

/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  font-weight: inherit;
}

/* リキッドレイアウト対応 */
html {
  font-size: 4.2666666667vw;
}
@media screen and (min-width: 375px) {
  html {
    font-size: 16px;
  }
}
@media (min-width: 768px) {
  html {
    font-size: 1.25vw;
  }
}
@media (min-width: 1280px) {
  html {
    font-size: 16px;
  }
}

body {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #302F37;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FFFFFF;
  overflow-wrap: break-word;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
}

@media (min-width: 768px) {
  a[href^="tel:"] {
    pointer-events: none;
  }
}

/* Make images easier to work with */
img,
picture {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

input:focus {
  outline: none;
}

ul, ol {
  list-style-type: none;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}

input[type=text],
input[type=tel],
input[type=email],
input[type=search],
textarea {
  appearance: none;
  background-color: transparent;
  background-image: none;
  border: none;
  border-radius: 0;
  color: inherit;
  font-family: inherit;
  font-size: 1em;
  padding: 0.4em 0.8em;
  width: 100%;
}

input[type=text]:focus,
input[type=tel]:focus,
input[type=email]:focus {
  box-shadow: none;
  outline: none;
}

input[type=search]:focus {
  outline: none;
}

input[type=search]:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s !important;
  -webkit-text-fill-color: #D1D1D1;
}

input[type=radio] {
  position: absolute;
  margin: 0;
  opacity: 0;
}

input[type=checkbox] {
  position: absolute;
  margin: 0;
  opacity: 0;
}

select {
  appearance: none;
  border: none;
  border-radius: 0;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: 1em;
  padding: 0.4em 0.8em;
  width: 100%;
}

select::-ms-expand {
  display: none;
}

select:focus {
  box-shadow: none;
  outline: none;
}

.l-header {
  position: absolute;
  top: 2.75rem;
  left: 1.5rem;
  z-index: 30;
  width: 15.4375rem;
  height: 3.5rem;
}
@media (min-width: 768px) {
  .l-header {
    top: 1.25rem;
    left: 2.5rem;
    width: calc(100% - 11rem);
    height: 4rem;
  }
}

.l-header__inner {
  width: 100%;
  padding: 0 1.5rem;
  height: inherit;
}
@media (min-width: 768px) {
  .l-header__inner {
    padding: 0 3rem;
  }
}

.l-hamburger {
  position: absolute;
  top: 2.75rem;
  right: 1.5rem;
  z-index: 41;
  width: 3.5rem;
  height: 3.5rem;
}
@media (min-width: 768px) {
  .l-hamburger {
    top: 1.25rem;
    right: 3rem;
    width: 4rem;
    height: 4rem;
  }
}

.l-drawer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  width: 100%;
  height: 100vh;
  padding: 2.75rem 0 0;
}
@media (min-width: 768px) {
  .l-drawer {
    padding: 1.25rem 0 0;
  }
}

.l-fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  width: 100%;
  height: 4.5rem;
}

.l-fixed-header__inner {
  width: 100%;
  padding: 0 1.5rem;
  height: inherit;
}
@media (min-width: 768px) {
  .l-fixed-header__inner {
    padding: 0 3rem 0 5.5rem;
  }
}

.l-footer {
  position: relative;
  z-index: 10;
  padding: 2rem 0 1rem;
}
@media (min-width: 768px) {
  .l-footer {
    padding: 3rem 0;
  }
}

.l-inner {
  width: 100%;
  max-width: 36.75rem;
  margin: 0 auto;
  padding: 0 1.5rem;
}
@media (min-width: 768px) {
  .l-inner {
    max-width: 67.5rem;
    padding: 0 2.5rem;
  }
}

.l-faq-drawer {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 43;
  width: 75%;
  height: 100vh;
}
@media (min-width: 768px) {
  .l-faq-drawer {
    width: 20%;
  }
}

.l-faq-drawer-overlay {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 42;
  width: 100%;
  height: 100vh;
}

.l-top__topics {
  margin-top: 8.75rem;
}
@media (min-width: 768px) {
  .l-top__topics {
    margin-top: 7.75rem;
  }
}

.l-top__philosophy {
  margin-top: 3.0625rem;
}
@media (min-width: 768px) {
  .l-top__philosophy {
    margin-top: 8.0625rem;
  }
}

.l-top__guide {
  margin-top: 4rem;
}
@media (min-width: 768px) {
  .l-top__guide {
    margin-top: 8rem;
  }
}

.l-top__company-info {
  margin-top: 4rem;
}
@media (min-width: 768px) {
  .l-top__company-info {
    margin-top: 8rem;
  }
}

.l-top__banner {
  margin-top: 2rem;
}
@media (min-width: 768px) {
  .l-top__banner {
    margin-top: 8rem;
  }
}

.l-top-mv {
  padding: 8.75rem 0 3rem;
}
@media (min-width: 768px) {
  .l-top-mv {
    padding: 12.5625rem 0 7.1875rem;
  }
}

.l-top-guide {
  padding: 4rem 0;
}
@media (min-width: 768px) {
  .l-top-guide {
    padding: 8rem 0 7.375rem;
  }
}

.l-top-banner {
  padding: 0 0 3rem;
}
@media (min-width: 768px) {
  .l-top-banner {
    padding: 4rem 0;
  }
}

.c-hamburger {
  border: 0.125rem solid #CD5954;
  border-radius: 50%;
  background-color: #CD5954;
}
@media (min-width: 768px) {
  .c-hamburger {
    transition: background-color 0.4s;
  }
}

@media (min-width: 768px) {
  .c-hamburger:focus,
.c-hamburger:hover {
    background-color: #FFFFFF;
  }
}

.c-hamburger__bars {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 2rem;
  height: 0.75rem;
  margin: auto;
}

.c-hamburger__bar {
  display: block;
  position: absolute;
  left: 0;
  height: 0.125rem;
  background-color: #FFFFFF;
}
@media (min-width: 768px) {
  .c-hamburger__bar {
    transition: background-color 0.4s;
  }
}

@media (min-width: 768px) {
  .c-hamburger:focus .c-hamburger__bar,
.c-hamburger:hover .c-hamburger__bar {
    background-color: #CD5954;
  }
}

.c-hamburger__bar:nth-child(1) {
  top: 0;
  width: 100%;
  transform: rotate(0);
  transition: transform 0.4s;
}

.c-hamburger.is-open .c-hamburger__bar:nth-child(1) {
  top: 0.3125rem;
  transform: rotate(30deg);
}

.c-hamburger__bar:nth-child(2) {
  top: 0.625rem;
  width: 75%;
  transform: rotate(0);
  transition: width 0.4s, transform 0.4s;
}

.c-hamburger.is-open .c-hamburger__bar:nth-child(2) {
  top: 0.3125rem;
  width: 100%;
  transform: rotate(-30deg);
}

.c-logo {
  display: block;
}
@media (min-width: 768px) {
  .c-logo {
    transition: opacity 0.4s;
  }
}

@media (min-width: 768px) {
  .c-logo:focus,
.c-logo:hover {
    opacity: 0.7;
  }
}

.c-banner {
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  padding: 0.5rem 2.0625rem 0.5rem 1.4375rem;
  min-height: 2.75rem;
  border: 0.0625rem solid #D1D1D1;
  border-radius: 0.5rem;
  position: relative;
}
@media (min-width: 768px) {
  .c-banner {
    padding: 1.125rem 3.8125rem 1.125rem 2.5625rem;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .c-banner {
    transition: opacity 0.4s;
  }
}

@media (min-width: 768px) {
  .c-banner:focus,
.c-banner:hover {
    opacity: 0.7;
  }
}

.c-banner__bottom-right {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  border-top: 0.9375rem solid transparent;
  border-right: 0.9375rem solid #F0F0F0;
  border-bottom: 0.9375rem solid #F0F0F0;
  border-left: 0.9375rem solid transparent;
  border-radius: 0 0 0.5rem 0;
}
@media (min-width: 768px) {
  .c-banner__bottom-right {
    border-top: 1.6875rem solid transparent;
    border-right: 1.6875rem solid #F0F0F0;
    border-bottom: 1.6875rem solid #F0F0F0;
    border-left: 1.6875rem solid transparent;
  }
}

.c-banner__bottom-right::before {
  color: #D1D1D1;
  content: "\f35d";
  font-family: "Font Awesome 5 Free";
  font-size: 0.5625rem;
  font-weight: 900;
  position: absolute;
}
@media (min-width: 768px) {
  .c-banner__bottom-right::before {
    font-size: 1rem;
  }
}

.c-button {
  display: block;
  background-color: #0055A2;
  border: 0.125rem solid #0055A2;
  border-radius: 1rem;
  box-shadow: -0.5rem -0.5rem 0.75rem 0 rgba(255, 255, 255, 0.4), 0.25rem 0.25rem 0.75rem 0 #d9d9d9;
  padding: 2rem 1rem 1.625rem;
  color: #FFFFFF;
  text-align: center;
  transform: scale(1);
  position: relative;
}
@media (min-width: 768px) {
  .c-button {
    padding: 1.8125rem 2.8125rem;
    transition: transform 445ms;
  }
}

@media (min-width: 768px) {
  .c-button:focus,
.c-button:hover {
    transform: scale(0.85);
  }
}

.c-button::after {
  color: #FFFFFF;
  content: "\f35d";
  font-family: "Font Awesome 5 Free";
  font-size: 1.5rem;
  font-weight: 900;
  position: absolute;
  bottom: 1rem;
  right: 1.125rem;
}
@media (min-width: 768px) {
  .c-button::after {
    font-size: 2.25rem;
    top: 50%;
    right: 2.625rem;
    bottom: auto;
    transform: translateY(-50%);
    transition: color 0.4s;
  }
}

.c-button.-size-thick {
  padding: 3.5rem 1rem;
}
@media (min-width: 768px) {
  .c-button.-size-thick {
    padding: 4.25rem 5.625rem;
  }
}

.c-button__text {
  display: block;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.2em;
  line-height: 2rem;
}
@media (min-width: 768px) {
  .c-button__text {
    font-size: 2rem;
    letter-spacing: 0.3em;
    line-height: 2.5rem;
    transition: color 0.4s;
  }
}

.c-button__text:nth-of-type(n + 2) {
  margin-top: 1.5rem;
}
@media (min-width: 768px) {
  .c-button__text:nth-of-type(n + 2) {
    margin-top: 1rem;
  }
}

.c-button__text.-font-medium {
  font-size: 1.125rem;
  line-height: 1.125rem;
}
@media (min-width: 768px) {
  .c-button__text.-font-medium {
    letter-spacing: 0.3em;
  }
}

.c-button__text.-font-small {
  font-size: 0.75rem;
  line-height: 0.75rem;
}
@media (min-width: 768px) {
  .c-button__text.-font-small {
    letter-spacing: 0.3em;
  }
}

.c-button__deco {
  width: 7.5rem;
  position: absolute;
  top: -4.5rem;
  left: -0.75rem;
}
@media (min-width: 768px) {
  .c-button__deco {
    width: 11.9375rem;
    top: initial;
    left: 0;
    bottom: -3.75rem;
  }
}

.c-button.-size-thick > .c-button__deco {
  width: 9.5rem;
  top: -2.5rem;
  left: -1rem;
}
@media (min-width: 768px) {
  .c-button.-size-thick > .c-button__deco {
    width: 17.4375rem;
    top: initial;
    bottom: -3rem;
    left: -3.75rem;
  }
}

.c-button__deco::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.c-button.-size-thick > .c-button__deco::before {
  content: "";
  display: block;
  padding-top: 46.0526315789%;
}

.c-button__deco > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.c-button-square {
  display: inline-block;
  padding: 0.5rem 0.875rem 0.625rem;
  background-color: #E6EEF6;
  border-radius: 0.5rem;
  box-shadow: -0.5rem -0.5rem 0.75rem rgba(255, 255, 255, 0.4), 0.5rem 0.5rem 0.75rem rgba(217, 207, 207, 0.7);
  position: relative;
}
@media (min-width: 768px) {
  .c-button-square {
    padding: 0.625rem 0.875rem 1.25rem;
    min-height: 13.625rem;
    transform: scale(1);
    transition: transform 445ms;
  }
}

@media (min-width: 768px) {
  .c-button-square:focus,
.c-button-square:hover {
    transform: scale(0.85);
  }
}

.c-button-square__logo {
  width: 4rem;
  margin: -0.625rem auto 0;
  border-radius: 50%;
  background-color: #FFFFFF;
  position: relative;
}
@media (min-width: 768px) {
  .c-button-square__logo {
    width: 7.5rem;
    margin-top: -0.3125rem;
  }
}

.c-button-square__logo::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.c-button-square__logo > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.c-button-square__title-en {
  display: inline-block;
  color: #CD5954;
  font-family: "futura-pt", sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  transform: rotate(-18deg);
  transform-origin: bottom center;
}
@media (min-width: 768px) {
  .c-button-square__title-en {
    font-size: 1.5rem;
  }
}

.c-button-square__title-ja {
  display: block;
  color: #302F37;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.2em;
  text-indent: 0.2em;
  line-height: 1rem;
  text-align: center;
}
@media (min-width: 768px) {
  .c-button-square__title-ja {
    font-size: 1rem;
    margin-top: 1rem;
  }
}

.c-button-square__bottom-right {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  border-top: 1.375rem solid transparent;
  border-right: 1.375rem solid #CD5954;
  border-bottom: 1.375rem solid #CD5954;
  border-left: 1.375rem solid transparent;
  border-radius: 0 0 0.5rem 0;
}
@media (min-width: 768px) {
  .c-button-square__bottom-right {
    border-top: 1.6875rem solid transparent;
    border-right: 1.6875rem solid #CD5954;
    border-bottom: 1.6875rem solid #CD5954;
    border-left: 1.6875rem solid transparent;
  }
}

.c-button-square__bottom-right::before {
  color: #FFFFFF;
  content: "\f35a";
  font-family: "Font Awesome 5 Free";
  font-size: 0.75rem;
  font-weight: 400;
  position: absolute;
}
@media (min-width: 768px) {
  .c-button-square__bottom-right::before {
    font-size: 1rem;
  }
}

.c-button-deco-left {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.6875rem 0.25rem;
  border-radius: 0.5rem;
  background-color: #FFFFFF;
  color: #302F37;
  font-family: inherit;
  text-align: left;
  position: relative;
}
@media (min-width: 768px) {
  .c-button-deco-left {
    padding: 0.25rem 0.75rem 0.25rem 0;
    transition: transform 445ms;
  }
}

.c-button-deco-left.-size-large {
  padding: 0.125rem 0.75rem 0.125rem 0.125rem;
}
@media (min-width: 768px) {
  .c-button-deco-left.-size-large {
    justify-content: center;
    padding: 0 2rem;
  }
}

@media (min-width: 768px) {
  .c-button-deco-left:focus,
.c-button-deco-left:hover {
    transform: scale(0.85);
  }
}

.c-button-deco-left__deco {
  display: block;
  width: 2rem;
  position: relative;
}
@media (min-width: 768px) {
  .c-button-deco-left__deco {
    width: 4rem;
  }
}

.c-button-deco-left.-size-large .c-button-deco-left__deco {
  width: 7.25rem;
}
@media (min-width: 768px) {
  .c-button-deco-left.-size-large .c-button-deco-left__deco {
    width: 7.5rem;
  }
}

.c-button-deco-left__deco::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.c-button-deco-left__deco > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.c-button-deco-left__text {
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.5rem;
  line-height: 1.2;
  padding-left: 1.3125rem;
  margin-left: 0.75rem;
  position: relative;
}
@media (min-width: 768px) {
  .c-button-deco-left__text {
    font-size: 1rem;
    letter-spacing: 0;
    padding-left: 0.75rem;
    margin-left: 0.25rem;
    transition: border-color 0.4s;
  }
}

.c-button-deco-left.-size-large .c-button-deco-left__text {
  font-size: 1rem;
  letter-spacing: 0.07em;
  line-height: 2.25rem;
  padding-left: 1.0625rem;
  margin-left: 0.8125rem;
}
@media (min-width: 768px) {
  .c-button-deco-left.-size-large .c-button-deco-left__text {
    font-size: 2rem;
    letter-spacing: 0.3em;
    margin-left: 1.3125rem;
  }
}

.c-button-deco-left__text::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 0.0625rem;
  height: 1.5rem;
  background-color: #8B90A0;
}
@media (min-width: 768px) {
  .c-button-deco-left__text::before {
    height: 2.5rem;
  }
}

.c-button-deco-left.-size-large .c-button-deco-left__text::before {
  height: 4rem;
}

.c-button-deco-left__bottom-right {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  border-top: 1.1875rem solid transparent;
  border-right: 1.1875rem solid #CD5954;
  border-bottom: 1.1875rem solid #CD5954;
  border-left: 1.1875rem solid transparent;
  border-radius: 0 0 0.5rem 0;
}
@media (min-width: 768px) {
  .c-button-deco-left__bottom-right {
    border-top: 1.625rem solid transparent;
    border-right: 1.625rem solid #CD5954;
    border-bottom: 1.625rem solid #CD5954;
    border-left: 1.625rem solid transparent;
  }
}

.c-button-deco-left.-size-large .c-button-deco-left__bottom-right {
  border-top: 2.125rem solid transparent;
  border-right: 2.125rem solid #CD5954;
  border-bottom: 2.125rem solid #CD5954;
  border-left: 2.125rem solid transparent;
  border-radius: 0 0 0.5rem 0;
}

.c-button-deco-left__bottom-right::before {
  color: #FFFFFF;
  content: "\f35a";
  font-family: "Font Awesome 5 Free";
  font-size: 0.75rem;
  font-weight: 400;
  position: absolute;
}
@media (min-width: 768px) {
  .c-button-deco-left__bottom-right::before {
    font-size: 1rem;
  }
}

.c-button-deco-left.-size-large .c-button-deco-left__bottom-right::before {
  font-size: 1rem;
}

.c-button-drawer-close {
  display: inline-block;
  width: 100%;
  text-align: center;
  border-radius: 0.5rem;
  padding: 0.875rem 1.3125rem;
  background-color: #FFFFFF;
  color: #0055A2;
  font-family: inherit;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 1.25rem;
}
@media (min-width: 768px) {
  .c-button-drawer-close {
    font-size: 0.875rem;
  }
}

.c-button-submit {
  background-color: #FFFFFF;
  border: 0.1875rem solid #CD5954;
  border-radius: 0.5rem;
  color: #CD5954;
  display: inline-block;
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-indent: 0.1em;
  line-height: 1.25rem;
  padding: 1.4375rem 0.8125rem;
  min-width: 17.5rem;
  position: relative;
  transition: background-color 0.4s, color 0.4s;
}
@media (min-width: 768px) {
  .c-button-submit {
    min-width: 15.1875rem;
  }
}

@media (min-width: 768px) {
  .c-button-submit:focus,
.c-button-submit:hover {
    background-color: #CD5954;
    color: #FFFFFF;
  }
}

.c-button-submit::after {
  color: #CD5954;
  content: "\f35a";
  font-family: "Font Awesome 5 Free";
  font-size: 1.25rem;
  font-weight: 900;
  position: absolute;
  top: 50%;
  right: 0.75rem;
  transform: translateY(-50%);
  transition: color 0.4s;
}

@media (min-width: 768px) {
  .c-button-submit:focus::after,
.c-button-submit:hover::after {
    color: #FFFFFF;
  }
}

.c-info-wrapper:not(:first-child) {
  margin-top: 2.5rem;
}

.c-info__title {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.15em;
  line-height: 1.5rem;
  padding-left: 1.5rem;
  position: relative;
  margin-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .c-info__title {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.c-info__title::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 0.5rem;
  height: 1.5rem;
  background-color: #CD5954;
  border-radius: 0.25rem;
}

.c-info__text {
  display: block;
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-indent: 0.1em;
  line-height: 1.5rem;
}

.c-info__text.-tel-layout {
  font-size: 1.125rem;
  padding: 1rem;
  background: #ffffff;
  border: 3px solid #cd5954;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.1em;
  color: #cd5954;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .c-info__text.-tel-layout {
    font-size: 0.875rem;
    color: inherit;
    justify-content: flex-start;
    background: none;
    border-width: 0;
    padding: 0rem;
    margin-bottom: 0rem;
  }
}

.c-info__text.-font-small {
  font-size: 0.8125rem;
  font-weight: 400;
  margin-top: 0.5rem;
}
@media (min-width: 768px) {
  .c-info__text.-font-small {
    margin-top: 0.875rem;
    font-size: 0.875rem;
  }
}

.c-input-text input[type=text],
.c-input-text input[type=tel],
.c-input-text input[type=email],
.c-input-text textarea {
  border: 0.0625rem solid #D1D1D1;
  border-radius: 0.3125rem;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0625rem;
  line-height: 1.5rem;
  padding: 0.4375rem 0.75rem;
}

.c-input-text textarea {
  display: block;
  height: 7.5rem;
  resize: vertical;
}

.c-input-radio {
  font-size: 0;
}

.c-input-radio label {
  display: inline-block;
  position: relative;
}

.c-input-radio .wpcf7-radio {
  display: flex;
  flex-direction: column;
}

.c-input-radio .wpcf7-list-item:not(:first-child) {
  margin-top: 1rem;
}

.c-input-radio input[type=radio] {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 1.25rem;
  height: 1.25rem;
}

.c-input-radio input[type=radio] + span {
  display: inline-block;
  padding-left: 2rem;
  font-size: 0.875rem;
  font-weight: 400;
}

.c-input-radio input[type=radio] + span::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
  transform: translateY(-50%);
  background-color: #E6EEF6;
  border-radius: 50%;
}

.c-input-radio input[type=radio] + span::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0.1875rem;
  width: 0.875rem;
  height: 0.875rem;
  transform: translateY(-50%);
  background-color: #0055A2;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.4s;
}

.c-input-radio input[type=radio]:checked + span::after {
  opacity: 1;
}

.custom-wpcf7c-confirmed .c-input-radio .wpcf7-list-item-label {
  display: none;
  font-size: 0;
}

.custom-wpcf7c-confirmed .c-input-radio .wpcf7c-conf-hidden + .wpcf7-list-item-label {
  display: inline-block;
  padding-left: 2rem;
  font-size: 0.875rem;
  font-weight: 400;
}

.c-input-select {
  display: inline-block;
  position: relative;
}

.c-input-select::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 0.0625rem;
  transform: translateY(-50%);
  width: 1.4375rem;
  height: calc(100% - 0.125rem);
  background-color: #F2F2F2;
  border-radius: 0 0.3125rem 0.3125rem 0;
  pointer-events: none;
  z-index: 1;
}

.c-input-select::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 0.5rem;
  width: 0.375rem;
  height: 0.375rem;
  border-right: 0.0625rem solid #000000;
  border-bottom: 0.0625rem solid #000000;
  transform: translateY(-50%) rotate(45deg) skew(-8deg, -8deg);
  pointer-events: none;
  z-index: 2;
}

.c-input-select select {
  background-color: #FFFFFF;
  border: 0.0625rem solid #D1D1D1;
  border-radius: 0.3125rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  padding: 0.5rem 3.5rem 0.4375rem 1.1875rem;
  width: 100%;
}

@-moz-document url-prefix() {
  .c-input-select select {
    padding: 0.5rem 3.25rem 0.4375rem 1rem;
  }
}
.c-input-date-select {
  display: inline-block;
  position: relative;
}

.c-input-date-select select {
  background-color: #FFFFFF;
  border: 0.0625rem solid #D1D1D1;
  border-radius: 0.3125rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  padding: 0.4375rem 6.3125rem 0.5rem 0.75rem;
  width: 100%;
}

.c-input-date-select input {
  border: 0.0625rem solid #D1D1D1;
  border-radius: 0.3125rem;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0625rem;
  line-height: 1.5rem;
  padding: 0.4375rem 0.75rem;
}

.c-input-checkbox label {
  display: inline-block;
  position: relative;
}

.c-input-checkbox input[type=checkbox] {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 1.125rem;
  height: 1.125rem;
}

.c-input-checkbox input[type=checkbox] + span {
  display: inline-block;
  padding-left: 1.875rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.c-input-checkbox.-text-highlighted input[type=checkbox] + span {
  color: #CD5954;
  font-weight: 700;
}

.c-input-checkbox input[type=checkbox] + span::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 1.125rem;
  height: 1.125rem;
  border: 0.125rem solid #D1D1D1;
  border-radius: 0.125rem;
  transition: border-color 0.4s;
}

.c-input-checkbox input[type=checkbox]:checked + span::before {
  border-color: #CD5954;
}

.c-input-checkbox input[type=checkbox] + span::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0.25rem;
  width: 0.625rem;
  height: 0.375rem;
  border-bottom: 0.125rem solid #CD5954;
  border-left: 0.125rem solid #CD5954;
  transform: translateY(-50%) rotate(-45deg);
  opacity: 0;
  transition: opacity 0.4s;
}

.c-input-checkbox input[type=checkbox]:checked + span::after {
  opacity: 1;
}

.c-notice {
  margin-top: 1rem;
  font-size: 0.75rem;
  line-height: 1.75rem;
  font-weight: 700;
  color: #CD5954;
}
@media (min-width: 768px) {
  .c-notice {
    letter-spacing: 0.05em;
  }
}

.c-explanation__items {
  background-color: #FFFFFF;
  padding: 1.5rem;
  border-radius: 0.5rem;
  margin-top: 2rem;
}
@media (min-width: 768px) {
  .c-explanation__items {
    padding: 3rem;
  }
}

.c-explanation__item:nth-child(n+2) {
  padding-top: 1.5rem;
  border-top: 0.0625rem solid #D1D1D1;
}

.c-explanation__item:not(:last-child) {
  padding-bottom: 0.9375rem;
}

.c-explanation-info__title {
  color: #CD5954;
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.15em;
  line-height: 1.5rem;
}
@media (min-width: 768px) {
  .c-explanation-info__title {
    font-size: 1rem;
  }
}

.c-explanation-info__description {
  color: #242526;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.15em;
  line-height: 1.5rem;
  margin-top: 0.5rem;
}
.c-explanation-info__description b {
  font-weight: 700;
}

.c-links-area {
  background-color: #FFFFFF;
  border-radius: 0.5rem;
  padding: 2rem 1.5rem;
  margin-top: 1.5rem;
}
@media (min-width: 768px) {
  .c-links-area {
    display: flex;
    margin-top: 2rem;
  }
}

.c-links-area__item-wrapper:nth-child(n+2) {
  margin: 1.5rem 0 0;
}
@media (min-width: 768px) {
  .c-links-area__item-wrapper:nth-child(n+2) {
    margin: 0 0 0 13.125rem;
  }
}

.c-links-area__item {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.15em;
  line-height: 2rem;
  padding-left: 1.5rem;
  position: relative;
}

.c-links-area__item::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 0.5rem;
  height: 1.5rem;
  border-radius: 0.25rem;
  background-color: #CD5954;
}

.c-links-area__link {
  color: inherit;
  display: flex;
  align-items: baseline;
}

.c-links-area__icon {
  width: 0.875rem;
  margin-left: 0.25rem;
  transform: translateY(0.1rem);
}

.c-links-area__item:nth-child(n+2) {
  margin-top: 1.5rem;
}

.c-links-area__description {
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 2.25rem;
  margin-top: 1.5625rem;
}
@media (min-width: 768px) {
  .c-links-area__description {
    margin-top: 1rem;
  }
}

.p-header {
  background-color: #FFFFFF;
  border-radius: 3rem;
}
@media (min-width: 768px) {
  .p-header {
    box-shadow: -0.5rem -0.5rem 0.75rem 0 rgba(255, 255, 255, 0.4), 0.5rem 0.5rem 0.75rem 0 rgba(196, 202, 209, 0.7);
  }
}

.p-header__content {
  display: flex;
  align-items: center;
}

.p-header__logo {
  width: 100%;
}
@media (min-width: 768px) {
  .p-header__logo {
    width: 12.5rem;
  }
}

@media (min-width: 768px) {
  .p-header__nav {
    margin-left: auto;
    height: inherit;
  }
}

@media (min-width: 768px) {
  .p-gnav__items {
    display: flex;
    height: inherit;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .p-gnav__item {
    display: flex;
    height: inherit;
    align-items: center;
    font-size: 0.75rem;
    font-weight: 700;
  }
}

@media (min-width: 768px) {
  .p-gnav__item:nth-child(n+2) {
    margin-left: 3.5rem;
  }
}

@media (min-width: 768px) {
  .p-gnav__link {
    display: flex;
    height: inherit;
    align-items: center;
    color: #0A061A;
    transition: color 0.4s;
  }
}

@media (min-width: 768px) {
  .p-gnav__link:focus,
.p-gnav__link:hover {
    color: #0055A2;
  }
}

.p-drawer {
  background-color: #004482;
  transform: translateX(105%);
  transition: transform 0.5s;
}

.p-drawer.is-open {
  transform: translateX(0);
}

.p-drawer__scroll {
  width: 100%;
  height: calc(100vh - 6.25rem);
  overflow-y: auto;
  padding: 2rem 0;
}
@media (min-width: 768px) {
  .p-drawer__scroll {
    height: calc(100vh - 5.25rem);
    padding: 6.5625rem 0;
  }
}

.p-drawer__inner {
  width: 100%;
  padding: 0 1.5rem;
}
@media (min-width: 768px) {
  .p-drawer__inner {
    padding: 0 2.5rem;
  }
}

.p-drawer__logo {
  background-color: #FFFFFF;
  width: 15.4375rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  border-radius: 3rem;
  padding: 0 1.5rem;
}
@media (min-width: 768px) {
  .p-drawer__logo {
    width: 18.5rem;
    height: 4rem;
    padding: 0 3rem;
  }
}

@media (min-width: 768px) {
  .p-drawer__nav {
    width: 100%;
    max-width: 50.5625rem;
    margin: 0 auto;
  }
}

.p-drawer__info {
  margin: 1.25rem 0 0;
}
@media (min-width: 768px) {
  .p-drawer__info {
    width: 100%;
    max-width: 50.5625rem;
    margin: 4rem auto 0;
  }
}

.p-drawer-nav__items {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .p-drawer-nav__items {
    flex-direction: row;
    justify-content: space-between;
  }
}

.p-drawer-nav__item-wrapper {
  display: contents;
}
@media (min-width: 768px) {
  .p-drawer-nav__item-wrapper {
    display: block;
  }
}

.p-drawer-nav__item-wrapper:nth-child(1) .p-drawer-nav__item:nth-child(1) {
  order: 1;
}
@media (min-width: 768px) {
  .p-drawer-nav__item-wrapper:nth-child(1) .p-drawer-nav__item:nth-child(1) {
    order: initial;
  }
}

.p-drawer-nav__item-wrapper:nth-child(1) .p-drawer-nav__item:nth-child(2) {
  order: 4;
}
@media (min-width: 768px) {
  .p-drawer-nav__item-wrapper:nth-child(1) .p-drawer-nav__item:nth-child(2) {
    order: initial;
  }
}

.p-drawer-nav__item-wrapper:nth-child(2) .p-drawer-nav__item:nth-child(1) {
  order: 2;
}
@media (min-width: 768px) {
  .p-drawer-nav__item-wrapper:nth-child(2) .p-drawer-nav__item:nth-child(1) {
    order: initial;
  }
}

.p-drawer-nav__item-wrapper:nth-child(2) .p-drawer-nav__item:nth-child(2) {
  order: 5;
}
@media (min-width: 768px) {
  .p-drawer-nav__item-wrapper:nth-child(2) .p-drawer-nav__item:nth-child(2) {
    order: initial;
  }
}

.p-drawer-nav__item-wrapper:nth-child(3) .p-drawer-nav__item:nth-child(1) {
  order: 3;
}
@media (min-width: 768px) {
  .p-drawer-nav__item-wrapper:nth-child(3) .p-drawer-nav__item:nth-child(1) {
    order: initial;
  }
}

.p-drawer-nav-link {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  color: #FFFFFF;
  position: relative;
}
@media (min-width: 768px) {
  .p-drawer-nav-link {
    padding: 1rem 0 1rem 4rem;
    transition: opacity 0.4s;
  }
}

@media (min-width: 768px) {
  .p-drawer-nav-link:focus,
.p-drawer-nav-link:hover {
    opacity: 0.7;
  }
}

.p-drawer-nav-link::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 1rem;
  height: 0.25rem;
  background-color: #FFFFFF;
  border-radius: 0.0625rem;
}
@media (min-width: 768px) {
  .p-drawer-nav-link::before {
    width: 3rem;
  }
}

.p-drawer-nav-link__ja,
.p-drawer-nav-link__en {
  display: block;
}

.p-drawer-nav-link__ja {
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-indent: 0.1em;
  line-height: 1;
}
@media (min-width: 768px) {
  .p-drawer-nav-link__ja {
    font-size: 1.5rem;
  }
}

.p-drawer-nav-link__en {
  opacity: 0.2;
  font-family: "futura-pt", sans-serif;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1rem;
  text-transform: capitalize;
  margin-top: 0.25rem;
}
@media (min-width: 768px) {
  .p-drawer-nav-link__en {
    font-size: 1rem;
  }
}

.p-drawer-info {
  color: #FFFFFF;
}

.p-drawer-info__title {
  font-family: "Avenir Next", "Arial", sans-serif;
  font-size: 0.875rem;
  font-weight: 800;
  letter-spacing: 0.2em;
  line-height: 1.25rem;
  text-transform: uppercase;
  padding-bottom: 0.5rem;
  border-bottom: 0.0625rem solid #C4C4C4;
}

.p-drawer-info__content {
  margin-top: 1rem;
}
@media (min-width: 768px) {
  .p-drawer-info__content {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .p-drawer-info__text-wrapper {
    flex: 0 1 37.0828182942%;
  }
}

.p-drawer-info__text {
  display: block;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-indent: 0.1em;
  line-height: 1.5rem;
}

.p-drawer-info__text.-font-small {
  font-size: 0.6875rem;
  font-weight: 400;
}
@media (min-width: 768px) {
  .p-drawer-info__text.-font-small {
    font-size: 0.75rem;
  }
}

.p-drawer-info__copyright {
  color: #FFFFFF;
  font-size: 0.6875rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-indent: 0.1em;
  line-height: 1rem;
  margin: 2.5rem 0 0;
}
@media (min-width: 768px) {
  .p-drawer-info__copyright {
    margin: 0 0 0 auto;
    flex: 0 0 auto;
  }
}

.p-fixed-header {
  background-color: #FFFFFF;
  border-bottom: 0.0625rem solid #D1D1D1;
  transform: translateY(-150%);
  transition: transform 0.4s;
}

.p-fixed-header.is-show {
  transform: translateY(0);
}

.p-fixed-header__content {
  display: flex;
  align-items: center;
}

.p-fixed-header__info {
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 1.25rem;
}
@media (min-width: 768px) {
  .p-fixed-header__info {
    font-size: 1rem;
  }
}

.p-fixed-header__info.-font-small {
  font-size: 0.6875rem;
  font-weight: 400;
}

@media (min-width: 768px) {
  .p-fixed-header__nav {
    margin-left: auto;
    height: inherit;
  }
}

.p-fixed-header__menu-icon {
  width: 3.5rem;
  height: 3.5rem;
  position: relative;
  z-index: 41;
  margin-left: auto;
}
@media (min-width: 768px) {
  .p-fixed-header__menu-icon {
    margin-left: 5rem;
  }
}

.p-fixed-header__drawer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  width: 100%;
  height: 100vh;
  padding: 0.5rem 0 2.75rem;
}

.p-footer {
  background-color: #302F37;
}

.p-footer__content {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .p-footer__content {
    flex-direction: row;
  }
}

.p-footer__left {
  display: contents;
}
@media (min-width: 768px) {
  .p-footer__left {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
  }
}

.p-footer__logo {
  width: 12.5rem;
  order: 1;
}
@media (min-width: 768px) {
  .p-footer__logo {
    order: initial;
  }
}

.p-footer__copyright {
  color: #FFFFFF;
  font-size: 0.6875rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-indent: 0.1em;
  line-height: 1rem;
  margin-top: 2rem;
  order: 4;
}
@media (min-width: 768px) {
  .p-footer__copyright {
    margin-top: auto;
    order: initial;
  }
}

.p-footer__right {
  order: 2;
  margin: 2rem 0 0;
}
@media (min-width: 768px) {
  .p-footer__right {
    order: initial;
    margin: 0 0 0 auto;
    flex: 0 1 calc(448 / 1000 * 100%);
    display: flex;
  }
}

@media (min-width: 768px) {
  .p-footer__info {
    flex: 0 1 calc((100% - 3rem) / 2);
  }
}

.p-footer__nav {
  margin: 1.625rem 0 0;
}
@media (min-width: 768px) {
  .p-footer__nav {
    margin: 0 0 0 auto;
    flex: 0 0 calc((100% - 3rem) / 2);
  }
}

.p-footer-info {
  color: #FFFFFF;
}

.p-footer-info__title {
  font-family: "Avenir Next", "Arial", sans-serif;
  font-size: 0.875rem;
  font-weight: 800;
  letter-spacing: 0.2em;
  line-height: 1.25rem;
  text-transform: uppercase;
  padding-bottom: 0.5rem;
  border-bottom: 0.0625rem solid #C4C4C4;
}

.p-footer-info__content {
  margin-top: 1rem;
}

.p-footer-info__text {
  display: block;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-indent: 0.1em;
  line-height: 1.5rem;
}

.p-footer-info__text.-font-small {
  font-size: 0.6875rem;
  font-weight: 400;
}

.p-footer-nav__title {
  color: #FFFFFF;
  font-family: "Avenir Next", "Arial", sans-serif;
  font-size: 0.875rem;
  font-weight: 800;
  letter-spacing: 0.2em;
  line-height: 1.25rem;
  text-transform: uppercase;
  padding-bottom: 0.5rem;
  border-bottom: 0.0625rem solid #C4C4C4;
}

.p-footer-nav__items {
  margin-top: 1rem;
}

.p-footer-nav__item {
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-indent: 0.1em;
  line-height: 2;
}

.p-footer-nav__link {
  display: block;
  color: #FFFFFF;
}
@media (min-width: 768px) {
  .p-footer-nav__link {
    transition: opacity 0.4s;
  }
}

@media (min-width: 768px) {
  .p-footer-nav__link:focus,
.p-footer-nav__link:hover {
    opacity: 0.7;
  }
}

.p-faq-drawer {
  background-color: #004482;
  transform: translateX(105%);
  transition: transform 0.4s;
}

.p-faq-drawer.is-open {
  transform: translateX(0);
}

.p-faq-drawer__scroll {
  width: 100%;
  height: inherit;
  padding: 7.5rem 0;
  overflow-y: auto;
}

.p-faq-drawer__inner {
  width: 100%;
  padding: 0 1.5rem;
  height: 100%;
}
@media (min-width: 768px) {
  .p-faq-drawer__inner {
    padding: 0 2.5rem;
  }
}

.p-faq-drawer__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.p-faq-drawer__button {
  margin-top: 2.5rem;
}

.p-faq-drawer-overlay {
  background-color: rgba(0, 0, 0, 0.48);
  display: none;
  opacity: 0;
  transition: opacity 0.4s;
}

.p-faq-drawer-overlay.is-show {
  display: block;
  opacity: 1;
}

.p-faq-nav__item {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.125rem;
}
@media (min-width: 768px) {
  .p-faq-nav__item {
    font-size: 0.875rem;
  }
}

.p-faq-nav__link {
  display: block;
  padding: 1rem 1.25rem 0.5rem 0;
  color: #FFFFFF;
  border-bottom: 0.0625rem solid #E4E4E4;
  position: relative;
}

.p-faq-nav__link::after {
  color: #FFFFFF;
  content: "\f358";
  font-family: "Font Awesome 5 Free";
  font-size: 0.75rem;
  font-weight: 400;
  position: absolute;
  right: 0;
}
@media (min-width: 768px) {
  .p-faq-nav__link::after {
    font-size: 0.875rem;
  }
}

.p-faq-search {
  position: relative;
}

.p-faq-search__round-text-deco {
  display: block;
  position: relative;
  left: -1.375rem;
  width: 8.75rem;
  height: 8.75rem;
  background-image: url(../img/resident/resident-deco.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
@media (min-width: 768px) {
  .p-faq-search__round-text-deco {
    width: 10.5rem;
    height: 10.5rem;
    left: -4.375rem;
  }
}

.p-faq-search__content {
  background-color: #0055A2;
  border-radius: 0.5rem;
  padding: 1.5rem 1.5rem 2.5rem;
  margin-top: -5.75rem;
  position: relative;
  z-index: 1;
}
@media (min-width: 768px) {
  .p-faq-search__content {
    padding: 2rem 4rem 4rem;
    margin-top: -6.1875rem;
  }
}

.p-faq-search__badge {
  position: absolute;
  top: -1.3125rem;
  left: 0.5625rem;
  z-index: 2;
  min-width: 4.5rem;
  min-height: 4.5rem;
  background-color: #CD5954;
  border-radius: 50%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 768px) {
  .p-faq-search__badge {
    min-width: 5.5rem;
    min-height: 5.1875rem;
    top: -2.0625rem;
    left: -2rem;
  }
}

.p-faq-search__badge > span {
  display: block;
  color: #FFFFFF;
  font-size: 1.25rem;
  font-weight: 700;
}
@media (min-width: 768px) {
  .p-faq-search__badge > span {
    font-size: 1.5rem;
  }
}

.p-faq-search__badge > span:nth-child(1) {
  font-size: 0.625rem;
  letter-spacing: 0.1em;
}
@media (min-width: 768px) {
  .p-faq-search__badge > span:nth-child(1) {
    font-size: 0.6875rem;
  }
}

.p-faq-search__title {
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.3em;
  text-align: center;
}
@media (min-width: 768px) {
  .p-faq-search__title {
    font-size: 1.25rem;
    text-align: left;
  }
}

.p-faq-search__form-wrapper {
  margin-top: 1.25rem;
}
@media (min-width: 768px) {
  .p-faq-search__form-wrapper {
    margin-top: 1rem;
  }
}

.p-faq-search__form {
  position: relative;
  background-image: url(../img/resident/search.svg) !important;
  background-repeat: no-repeat;
  background-position: left 0.75rem center;
  background-size: 1.5rem 1.5rem;
}

.p-faq-search__form input[type=search] {
  border-radius: 0.5rem;
  box-shadow: -0.375rem -0.375rem 0.75rem 0 #0062ba inset, 0.5rem 0.5rem 0.75rem 0 #00488a inset;
  color: #D1D1D1;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: 2.25rem;
  padding: 0.625rem 0.6875rem 0.625rem 2.75rem;
}
@media (min-width: 768px) {
  .p-faq-search__form input[type=search] {
    font-size: 0.875rem;
  }
}

.p-faq-search__form input[type=search]::placeholder {
  color: #D1D1D1;
}

.p-faq-search__link-induction {
  color: #FFFFFF;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.3em;
  text-align: center;
  margin-top: 1.5rem;
}
@media (min-width: 768px) {
  .p-faq-search__link-induction {
    font-size: 0.875rem;
    text-align: left;
    margin-top: 1.1875rem;
  }
}

.p-faq-search__buttons {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.5rem;
  margin: 1.125rem 0 0;
}
@media (min-width: 768px) {
  .p-faq-search__buttons {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 0;
    column-gap: 1.9375rem;
    margin: 0.8125rem 0 -0.5rem;
  }
}

.p-faq-search__button {
  box-shadow: -0.5rem -0.25rem 0.5rem 0 #0062ba, 0.25rem 0.25rem 0.5rem 0 #00488a;
}

.p-faq-search__dropdown {
  width: 88.9908256881%;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.p-faq-dropdown {
  background-color: #004482;
  display: none;
}

.p-faq-dropdown.is-open {
  display: block;
}

.p-faq-dropdown__scroll {
  width: 100%;
  height: inherit;
  padding: 0.5rem 0 1.5rem;
  overflow-y: auto;
}

.p-faq-dropdown__inner {
  width: 100%;
  padding: 0 1.5rem;
  height: 100%;
}
@media (min-width: 768px) {
  .p-faq-dropdown__inner {
    padding: 0 2.5rem;
  }
}

.p-faq-dropdown__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.p-faq-dropdown__button {
  margin-top: 2.5rem;
}

.p-info {
  display: block;
  padding: 1.4375rem 0 0.9375rem;
}
@media (min-width: 768px) {
  .p-info {
    display: flex;
    align-items: flex-start;
    padding: 1.5rem 0 0.9375rem;
    transition: box-shadow 0.4s;
  }
}

@media (min-width: 768px) {
  .p-info:focus,
.p-info:hover {
    box-shadow: 0 -0.3125rem 0.1875rem -0.1875rem rgba(0, 0, 0, 0.3), 0 0.3125rem 0.1875rem -0.1875rem rgba(0, 0, 0, 0.3);
  }
}

@media (min-width: 768px) {
  .p-info:first-child:focus,
.p-info:first-child:hover {
    box-shadow: 0 0.3125rem 0.1875rem -0.1875rem rgba(0, 0, 0, 0.3);
  }
}

@media (min-width: 768px) {
  .p-info:last-child:focus,
.p-info:last-child:hover {
    box-shadow: 0 -0.3125rem 0.1875rem -0.1875rem rgba(0, 0, 0, 0.3);
  }
}

.p-info__meta {
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .p-info__meta {
    flex: 0 0 auto;
  }
}

.p-info__published {
  color: #302F37;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.07em;
  text-indent: 0.07em;
  line-height: 1.25rem;
}

.p-info__category-wrapper {
  margin-left: 0.5rem;
  display: flex;
}

.p-info__category {
  display: inline-block;
  padding: 0.25rem 0.5rem 0.3125rem;
  background-color: #CD5954;
  color: #FFFFFF;
  font-size: 0.6875rem;
  font-weight: 700;
  letter-spacing: 0.07em;
  line-height: 1;
}

.p-info__title {
  color: #302F37;
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.07em;
  text-indent: 0.07em;
  line-height: 1.5rem;
  margin: 0.5rem 0 0;
}
@media (min-width: 768px) {
  .p-info__title {
    flex: 0 1 100%;
    margin: 0 0 0 1.5rem;
  }
}

.p-pagination {
  display: flex;
  justify-content: center;
}

.p-pagination__pager {
  display: inline-block;
  background-color: #FFFFFF;
  border: 0.0625rem solid #D1D1D1;
  color: #000000;
  font-family: "Roboto", sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-indent: 0.1em;
  line-height: 1;
  text-align: center;
  width: 2.5rem;
  padding: 0.625rem 0;
}

.p-pagination__pager.is-current {
  background-color: #302F37;
  color: #FFFFFF;
}

@media (min-width: 768px) {
  .p-pagination > a {
    transition: background-color 0.4s, color 0.4s;
  }
}

@media (min-width: 768px) {
  .p-pagination > a:focus,
.p-pagination > a:hover {
    background-color: #302F37;
    color: #FFFFFF;
  }
}

.p-pagination__dots {
  display: inline-block;
  margin: 0 0.9375rem;
}

.p-accordion {
  cursor: pointer;
}

.p-accordion__head {
  background-color: #0055A2;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  padding: 1rem;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .p-accordion__head {
    padding: 1.25rem 3rem 1.25rem 2rem;
    transition: border-radius 0.4s;
  }
}

.p-accordion__head.is-open {
  border-radius: 0.5rem 0.5rem 0 0;
}

.p-accordion__question {
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: 700;
  line-height: 2rem;
  padding: 0 1rem 0 2.4375rem;
  position: relative;
}
@media (min-width: 768px) {
  .p-accordion__question {
    font-size: 1.5rem;
    padding: 0 1rem 0 2.875rem;
    letter-spacing: 0.2em;
  }
}

.p-accordion__question::before {
  content: "q";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  color: #FFFFFF;
  font-family: "futura-pt-bold", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .p-accordion__question::before {
    font-size: 2rem;
  }
}

.p-accordion__toggle-button {
  display: block;
  width: 1rem;
  height: 1rem;
  margin-left: auto;
  position: relative;
  transform: rotate(0);
  transition: transform 0.4s;
}

.p-accordion__toggle-button span {
  display: block;
  position: absolute;
  width: 100%;
  height: 0.25rem;
  background-color: #FFFFFF;
}

.p-accordion__toggle-button span:nth-child(1) {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.p-accordion__toggle-button span:nth-child(2) {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
}

.p-accordion__head.is-open .p-accordion__toggle-button {
  transform: rotate(-45deg);
}

.p-accordion__body {
  background-color: #FFFFFF;
  border-right: 0.125rem solid #0055A2;
  border-bottom: 0.125rem solid #0055A2;
  border-left: 0.125rem solid #0055A2;
  border-radius: 0 0 0.5rem 0.5rem;
  padding: 1rem 1.6875rem 1.875rem 0.875rem;
  display: none;
}
@media (min-width: 768px) {
  .p-accordion__body {
    padding: 1.5rem 1.375rem 1.875rem 2rem;
  }
}

.p-accordion__answer-wrapper {
  padding-left: 2.4375rem;
  position: relative;
}
@media (min-width: 768px) {
  .p-accordion__answer-wrapper {
    padding-left: 2.75rem;
  }
}

.p-accordion__answer-wrapper::before {
  content: "a";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  color: #CD5954;
  font-family: "futura-pt-bold", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 1.75rem;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .p-accordion__answer-wrapper::before {
    font-size: 2rem;
  }
}

.p-accordion__answer {
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 1.75rem;
}
@media (min-width: 768px) {
  .p-accordion__answer {
    line-height: 2.25rem;
  }
}

.p-accordion__image {
  position: relative;
  margin-top: 1rem;
}
@media (min-width: 768px) {
  .p-accordion__image {
    margin-top: 2rem;
  }
}

.p-accordion__image::before {
  content: "";
  display: block;
  padding-top: 57.6131687243%;
}
@media (min-width: 768px) {
  .p-accordion__image::before {
    padding-top: 62.4719101124%;
  }
}

.p-accordion__image > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

@media (min-width: 1280px) {
  .u-hidden-lg {
    display: none;
  }
}

@media (min-width: 768px) {
  .u-hidden-pc {
    display: none;
  }
}

.u-hidden-sp {
  display: none;
}
@media (min-width: 768px) {
  .u-hidden-sp {
    display: block;
  }
}

.u-hidden-sp {
  display: none;
}
@media (min-width: 768px) {
  .u-hidden-sp {
    display: block;
  }
}

.u-text-accent {
  color: #CD5954;
}

.top-mv {
  background-color: #E6EEF6;
}

.top-mv__inner {
  width: 100%;
  max-width: 23.4375rem;
  margin: 0 auto;
  padding: 0 1.5rem;
}
@media (min-width: 768px) {
  .top-mv__inner {
    max-width: 67.5rem;
    padding: 0 2.5rem;
  }
}

@media (min-width: 768px) {
  .top-mv__content {
    display: flex;
    align-items: center;
  }
}

.top-mv__text-wrapper {
  font-weight: 700;
  width: calc(100% - 3rem);
  margin: 0 auto;
}
@media (min-width: 768px) {
  .top-mv__text-wrapper {
    width: auto;
    margin: 0;
    flex: 0 1 auto;
  }
}

.top-mv__title {
  font-size: 3rem;
  letter-spacing: 0.365em;
  line-height: 4rem;
}
@media (min-width: 768px) {
  .top-mv__title {
    font-size: 3.5rem;
    letter-spacing: 0.3em;
    line-height: 4.5rem;
  }
}

.top-mv__lead {
  font-size: 1.25rem;
  letter-spacing: 0.3em;
  margin-top: 0.75rem;
}
@media (min-width: 768px) {
  .top-mv__lead {
    margin-top: 2.125rem;
  }
}

.top-mv__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  row-gap: 1.25rem;
  margin: 2.3125rem 0 0;
}
@media (min-width: 768px) {
  .top-mv__buttons {
    column-gap: 3rem;
    row-gap: 3rem;
    margin: 0 0 0 auto;
    flex: 0 0 calc(484 / 1000 * 100%);
  }
}

.top-topics__content {
  position: relative;
}

.top-topics__deco {
  width: 6rem;
  position: absolute;
  top: -1.5625rem;
  right: 1.5rem;
}
@media (min-width: 768px) {
  .top-topics__deco {
    width: 7.25rem;
    top: -2rem;
    right: 2.5rem;
  }
}

.top-topics__deco::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.top-topics__deco > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.top-topics__head {
  display: flex;
  align-items: flex-end;
}

.top-topics__title {
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.3em;
  line-height: 1.2;
}

.top-topics__archive-link-wrapper {
  margin-left: 0.625rem;
}

.top-topics__archive-link {
  color: #CD5954;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.2em;
  text-indent: 0.2em;
  line-height: 1.25rem;
  border-bottom: 0.25rem solid #CD5954;
}
@media (min-width: 768px) {
  .top-topics__archive-link {
    transition: opacity 0.4s;
  }
}

@media (min-width: 768px) {
  .top-topics__archive-link:focus,
.top-topics__archive-link:hover {
    opacity: 0.7;
  }
}

.top-topics__items {
  border: 0.25rem solid #CD5954;
  border-radius: 0.5rem;
  padding: 0rem 1.25rem 0.3125rem;
  margin-top: 1.125rem;
}
@media (min-width: 768px) {
  .top-topics__items {
    padding: 0.3125rem 2.75rem 0.75rem;
    margin-top: 1.125rem;
  }
}

.top-topics__item:nth-child(n+2) {
  border-top: 0.0625rem solid #D1D1D1;
}

.top-philosophy__inner {
  width: 100%;
  max-width: 36.75rem;
  margin: 0 auto;
  padding: 0 1.5rem;
}
@media (min-width: 768px) {
  .top-philosophy__inner {
    max-width: 78.75rem;
    max-width: calc(50% + 31.25rem + 2.5rem);
    margin: 0 0 0 auto;
    padding: 0 0 0 2.5rem;
  }
}

.top-philosophy__content {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .top-philosophy__content {
    flex-direction: row;
    align-items: center;
  }
}

.top-philosophy__text-wrapper {
  display: contents;
}
@media (min-width: 768px) {
  .top-philosophy__text-wrapper {
    display: block;
    flex: 0 1 48.3606557377%;
  }
}

.top-philosophy__lead {
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-indent: 0.1em;
  line-height: 2.5rem;
  order: 1;
}
@media (min-width: 768px) {
  .top-philosophy__lead {
    font-size: 2.5rem;
    letter-spacing: 0.14em;
    line-height: 4.5rem;
    order: initial;
  }
}

.top-philosophy__description {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 2rem;
  order: 3;
  margin-top: 1.5625rem;
}
@media (min-width: 768px) {
  .top-philosophy__description {
    letter-spacing: 0.1em;
    line-height: 2.5rem;
    order: initial;
  }
}

.top-philosophy__image {
  position: relative;
  order: 2;
  margin: 1.375rem 0 0;
}
@media (min-width: 768px) {
  .top-philosophy__image {
    flex: 0 0 44.262295082%;
    margin: 0 0 0 auto;
  }
}

.top-philosophy__image::before {
  content: "";
  display: block;
  padding-top: 56.5749235474%;
}
@media (min-width: 768px) {
  .top-philosophy__image::before {
    padding-top: 113.3333333333%;
  }
}

.top-philosophy__image > picture > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.top-philosophy__deco {
  width: 7.4375rem;
  position: absolute;
  top: -4.5625rem;
  right: -0.8125rem;
}
@media (min-width: 768px) {
  .top-philosophy__deco {
    width: 15rem;
    top: auto;
    right: auto;
    bottom: -1.3125rem;
    left: -10.875rem;
  }
}

.top-philosophy__deco::before {
  content: "";
  display: block;
  padding-top: 92.4369747899%;
}

.top-philosophy__deco > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.top-guide {
  position: relative;
}

.top-guide::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  width: 100%;
  max-width: 100%;
  height: 100%;
  background-color: #E6EEF6;
}
@media (min-width: 768px) {
  .top-guide::before {
    max-width: calc(100% - 2.5rem * 2);
    border-radius: 0.5rem;
  }
}

.top-guide__inner {
  width: 100%;
  max-width: 23.4375rem;
  margin: 0 auto;
  padding: 0 1.5rem;
}
@media (min-width: 768px) {
  .top-guide__inner {
    max-width: 68.5rem;
    padding: 0 2.5rem;
  }
}

.top-guide__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  row-gap: 1.375rem;
}
@media (min-width: 768px) {
  .top-guide__buttons {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 3rem;
    row-gap: 2.25rem;
  }
}

.top-guide__description {
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.21em;
  line-height: 2.25rem;
  margin: 1.5rem 0 0;
}
@media (min-width: 768px) {
  .top-guide__description {
    width: 100%;
    max-width: 45rem;
    margin: 3rem auto 0;
  }
}

@media (min-width: 768px) {
  .top-company-info__content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.top-company-info__image {
  width: 100%;
  position: relative;
}
@media (min-width: 768px) {
  .top-company-info__image {
    width: auto;
    flex: 0 0 calc(362 / 1000 * 100%);
    transform: translateY(-50px);
  }
}

.top-company-info__image::before {
  content: "";
  display: block;
  padding-top: 20.4892966361%;
}
.top-company-info__image > picture > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.top-company-info__text-wrapper {
  margin: 2.5rem 0 0;
}
@media (min-width: 768px) {
  .top-company-info__text-wrapper {
    margin: 0 0 0 6.25rem;
    flex: 0 1 calc(609 / 1000 * 100%);
  }
}

.top-company-info__title {
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.2em;
  line-height: 2rem;
}
@media (min-width: 768px) {
  .top-company-info__title {
    font-size: 2rem;
  }
}

.top-company-info__description {
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-indent: 0.1em;
  line-height: 2.25rem;
  margin-top: 1rem;
}
@media (min-width: 768px) {
  .top-company-info__description {
    margin-top: 2rem;
  }
}

.top-company-info__link-wrapper {
  margin-top: 1.25rem;
  text-align: right;
}
@media (min-width: 768px) {
  .top-company-info__link-wrapper {
    margin-top: 3.25rem;
  }
}

.top-company-info__link {
  display: inline-block;
  padding-bottom: 0.25rem;
  color: #CD5954;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.2em;
  text-indent: 0.2em;
  border-bottom: 0.25rem solid #CD5954;
}
@media (min-width: 768px) {
  .top-company-info__link {
    transition: opacity 0.4s;
  }
}

@media (min-width: 768px) {
  .top-company-info__link:focus,
.top-company-info__link:hover {
    opacity: 0.7;
  }
}

@media (min-width: 768px) {
  .top-banner {
    border-top: 0.25rem dotted #0055A2;
  }
}

.top-banner__inner {
  width: 100%;
  max-width: 47.8125rem;
  margin: 0 auto;
  padding: 0 1.5rem;
}
@media (min-width: 768px) {
  .top-banner__inner {
    padding: 0 2.5rem;
  }
}

.top-banner__items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  row-gap: 1.5625rem;
}
@media (min-width: 768px) {
  .top-banner__items {
    column-gap: 3rem;
    row-gap: 2rem;
  }
}

.wp-pagenavi {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.wp-pagenavi a.page,
.wp-pagenavi a.first,
.wp-pagenavi a.last,
.wp-pagenavi span.current {
  display: inline-block;
  background-color: #FFFFFF;
  border: 0.0625rem solid #D1D1D1;
  color: #000000;
  font-family: "Roboto", sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-indent: 0.1em;
  line-height: 1;
  text-align: center;
  width: 2.5rem;
  padding: 0.625rem 0;
}

.wp-pagenavi span.current {
  background-color: #302F37;
  color: #FFFFFF;
}

@media (min-width: 768px) {
  .wp-pagenavi a {
    transition: background-color 0.4s, color 0.4s;
  }
}

@media (min-width: 768px) {
  .wp-pagenavi a:focus,
.wp-pagenavi a:hover {
    background-color: #302F37;
    color: #FFFFFF;
  }
}

.wp-pagenavi span.extend {
  display: inline-block;
  margin: 0 0.9375rem;
}