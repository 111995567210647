@use "../../global/" as *;

.l-header {
  position: absolute;
  top: rem(44);
  left: rem(24);
  z-index: $layer-header;
  width: rem(247);
  height: rem(strip-unit($header-height-sp));
  @include mq("md") {
    top: rem(20);
    left: rem(40);
    width: calc(100% - #{rem(176)});
    height: rem(strip-unit($header-height-pc));
  }
}

.l-header__inner {
  width: 100%;
  padding: 0 rem(strip-unit($padding-sp));
  height: inherit;
  @include mq('md') {
    padding: 0 rem(48);
  }
}