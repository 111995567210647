@use "../../global/" as *;

.c-logo {
  display: block;
  @include mq('md') {
    transition: opacity $transition-duration;
  }
}

.c-logo:focus,
.c-logo:hover {
  @include mq('md') {
    opacity: $opacity;
  }
}