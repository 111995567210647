@use "../../global/" as *;

// PC、タブレットの時に非表示
.u-hidden-lg {
  @include mq("lg") {
    display: none;
  }
}

.u-hidden-pc {
  @include mq("md") {
    display: none;
  }
}

// スマホの時に非表示
.u-hidden-sp {
  display: none;
  @include mq("md") {
    display: block;
  }
}
// スマホの時に非表示
.u-hidden-sp {
  display: none;
  @include mq("md") {
    display: block;
  }
}