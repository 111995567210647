@use "../../global/" as *;

.c-input-text input[type="text"],
.c-input-text input[type="tel"],
.c-input-text input[type="email"],
.c-input-text textarea {
	border: rem(1) solid $gray-500;
	border-radius: rem(5);
	font-size: rem(14);
  font-weight: $regular;
  letter-spacing: rem(1);
  line-height: rem(24);
	padding: rem(7) rem(12);
}

.c-input-text textarea {
  display: block;
  height: rem(120);
  resize: vertical;
}