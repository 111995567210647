@use "../../global/" as *;

.p-drawer-info {
  color: $white;
}

.p-drawer-info__title {
  font-family: 'Avenir Next', 'Arial', sans-serif;
  font-size: rem(14);
  font-weight: $extra-bold;
  letter-spacing: calc(20 / 100 * 1em);
  line-height: rem(20);
  text-transform: uppercase;
  padding-bottom: rem(8);
  border-bottom: rem(1) solid #C4C4C4;
}

.p-drawer-info__content {
  margin-top: rem(16);
  @include mq("md") {
    display: flex;
    align-items: center;
  }
}

.p-drawer-info__text-wrapper {
  @include mq('md') {
    flex: 0 1 calc(300 / 809 * 100%);
  }
}

.p-drawer-info__text {
  display: block;
  font-size: rem(12);
  font-weight: $bold;
  letter-spacing: calc(10 / 100 * 1em);
  text-indent: calc(10 / 100 * 1em);
  line-height: rem(24);
}

.p-drawer-info__text.-font-small {
  font-size: rem(11);
  font-weight: $regular;
  @include mq("md") {
    font-size: rem(12);
  }
}

.p-drawer-info__copyright {
  color: $white;
  font-size: rem(11);
  font-weight: $bold;
  letter-spacing: calc(10 / 100 * 1em);
  text-indent: calc(10 / 100 * 1em);
  line-height: rem(16);
  margin: rem(40) 0 0;
  @include mq("md") {
    margin: 0 0 0 auto;
    flex: 0 0 auto;
  }
}