@use "../../global/" as *;

.p-accordion {
  cursor: pointer;
}

.p-accordion__head {
  background-color: $brand-main;
  border-radius: rem(8) rem(8) rem(8) rem(8);
  padding: rem(16);
  display: flex;
  align-items: center;
  @include mq('md') {
    padding: rem(20) rem(48) rem(20) rem(32);
    transition: border-radius $transition-duration;
  }
}

.p-accordion__head.is-open {
  border-radius: rem(8) rem(8) 0 0;
}

.p-accordion__question {
  color: $white;
  font-size: rem(16);
  font-weight: $bold;
  line-height: rem(32);
  padding: 0 rem(16) 0 rem(39);
  position: relative;
  @include mq('md') {
    font-size: rem(24);
    padding: 0 rem(16) 0 rem(46);
    letter-spacing: calc(20 / 100 * 1em);
  }
}

.p-accordion__question::before {
  content: "q";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  color: $white;
  font-family: 'futura-pt-bold', sans-serif;
  font-size: rem(24);
  font-weight: $bold;
  letter-spacing: calc(10 / 100 * 1em);
  text-transform: uppercase;
  @include mq('md') {
    font-size: rem(32);
  }
}

.p-accordion__toggle-button {
  display: block;
  width: rem(16);
  height: rem(16);
  margin-left: auto;
  position: relative;
  transform: rotate(0);
  transition: transform $transition-duration;
}

.p-accordion__toggle-button span {
  display: block;
  position: absolute;
  width: 100%;
  height: rem(4);
  background-color: $white;
}

.p-accordion__toggle-button span:nth-child(1) {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.p-accordion__toggle-button span:nth-child(2) {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
}

.p-accordion__head.is-open .p-accordion__toggle-button {
  transform: rotate(-45deg);
}

.p-accordion__body {
  background-color: $white;
  border-right: rem(2) solid $brand-main;
  border-bottom: rem(2) solid $brand-main;
  border-left: rem(2) solid $brand-main;
  border-radius: 0 0 rem(8) rem(8);
  padding: rem(16) rem(27) rem(30) rem(14);
  display: none;
  @include mq('md') {
    padding: rem(24) rem(22) rem(30) rem(32);
  }
}

.p-accordion__answer-wrapper {
  padding-left: rem(39);
  position: relative;
  @include mq('md') {
    padding-left: rem(44);
  }
}

.p-accordion__answer-wrapper::before {
  content: "a";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  color: $brand-accent;
  font-family: 'futura-pt-bold', sans-serif;
  font-size: rem(24);
  font-weight: $bold;
  letter-spacing: calc(10 / 100 * 1em);
  line-height: rem(28);
  text-transform: uppercase;
  @include mq('md') {
    font-size: rem(32);
  }
}

.p-accordion__answer {
  font-size: rem(14);
  font-weight: $bold;
  letter-spacing: calc(10 / 100 * 1em);
  line-height: rem(28);
  @include mq('md') {
    line-height: rem(36);
  }
}

.p-accordion__image {
  position: relative;
  margin-top: rem(16);
  @include mq('md') {
    margin-top: rem(32);
  }
}

.p-accordion__image::before {
  content: "";
  display: block;
  padding-top: calc(140 / 243 * 100%);
  @include mq('md') {
    padding-top: calc(556 / 890 * 100%);
  }
}

.p-accordion__image > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}