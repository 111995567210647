@use "../../global/" as *;

.p-drawer-nav__items {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  @include mq("md") {
    flex-direction: row;
    justify-content: space-between;
  }
}

.p-drawer-nav__item-wrapper {
  display: contents;
  @include mq("md") {
    display: block;
  }
}

.p-drawer-nav__item-wrapper:nth-child(1) .p-drawer-nav__item:nth-child(1) {
  order: 1;
  @include mq("md") {
    order: initial;
  }
}

.p-drawer-nav__item-wrapper:nth-child(1) .p-drawer-nav__item:nth-child(2) {
  order: 4;
  @include mq("md") {
    order: initial;
  }
}

.p-drawer-nav__item-wrapper:nth-child(2) .p-drawer-nav__item:nth-child(1) {
  order: 2;
  @include mq("md") {
    order: initial;
  }
}

.p-drawer-nav__item-wrapper:nth-child(2) .p-drawer-nav__item:nth-child(2) {
  order: 5;
  @include mq("md") {
    order: initial;
  }
}

.p-drawer-nav__item-wrapper:nth-child(3) .p-drawer-nav__item:nth-child(1) {
  order: 3;
  @include mq('md') {
    order: initial;
  }
}