@use "../../global/" as *;

.p-footer {
  background-color: $footer-bg-color;
}

.p-footer__content {
  display: flex;
  flex-direction: column;
  @include mq('md') {
    flex-direction: row;
  }
}

.p-footer__left {
  display: contents;
  @include mq('md') {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
  }
}

.p-footer__logo {
  width: rem(200);
  order: 1;
  @include mq('md') {
    order: initial;
  }
}

.p-footer__copyright {
  color: $white;
  font-size: rem(11);
  font-weight: $bold;
  letter-spacing: calc(10 / 100 * 1em);
  text-indent: calc(10 / 100 * 1em);
  line-height: rem(16);
  margin-top: rem(32);
  order: 4;
  @include mq('md') {
    margin-top: auto;
    order: initial;
  }
}

.p-footer__right {
  order: 2;
  margin: rem(32) 0 0;
  @include mq('md') {
    order: initial;
    margin: 0 0 0 auto;
    flex: 0 1 calc(448 / #{strip-unit($inner-pc)} * 100%);
    display: flex;
  }
}

.p-footer__info {
  @include mq('md') {
    flex: 0 1 calc((100% - #{rem(48)}) / 2);
  }
}

.p-footer__nav {
  margin: rem(26) 0 0;
  @include mq('md') {
    margin: 0 0 0 auto;
    flex: 0 0 calc((100% - #{rem(48)}) / 2);
  }
}