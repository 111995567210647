@use "../../global/" as *;

.c-button {
  display: block;
  background-color: $brand-main;
  border: rem(2) solid $brand-main;
  border-radius: rem(16);
  box-shadow: rem(-8) rem(-8) rem(12) 0 rgba($white, 0.4), rem(4) rem(4) rem(12) 0 rgba(#D9D9D9, 1);
  padding: rem(32) rem(16) rem(26);
  color: $white ;
  text-align: center;
  transform: scale(1);
  position: relative;
  @include mq("md") {
    padding: rem(29) rem(45);
    transition: transform 445ms;
  }
}

.c-button:focus,
.c-button:hover {
  @include mq('md') {
    transform: scale(0.85);
  }
}

.c-button::after {
  color: $white;
  content: '\f35d';
  font-family: 'Font Awesome 5 Free';
  font-size: rem(24);
  font-weight: 900;
  position: absolute;
  bottom: rem(16);
  right: rem(18);
  @include mq('md') {
    font-size: rem(36);
    top: 50%;
    right: rem(42);
    bottom: auto;
    transform: translateY(-50%);
    transition: color $transition-duration;
  }
}

.c-button.-size-thick {
  padding: rem(56) rem(16);
  @include mq('md') {
    padding: rem(68) rem(90);
  }
}

.c-button__text {
  display: block;
  font-size: rem(24);
  font-weight: $bold;
  letter-spacing: calc(20 / 100 * 1em);
  line-height: rem(32);
  @include mq('md') {
    font-size: rem(32);
    letter-spacing: calc(30 / 100 * 1em);
    line-height: rem(40);
    transition: color $transition-duration;
  }
}

.c-button__text:nth-of-type(n + 2) {
  margin-top: rem(24);
  @include mq('md') {
    margin-top: rem(16);
  }
}

.c-button__text.-font-medium {
  font-size: rem(18);
  line-height: rem(18);
  @include mq('md') {
    letter-spacing: calc(30 / 100 * 1em);
  }
}

.c-button__text.-font-small {
  font-size: rem(12);
  line-height: rem(12);
  @include mq('md') {
    letter-spacing: calc(30 / 100 * 1em);
  }
}

.c-button__deco {
  width: rem(120);
  position: absolute;
  top: rem(-72);
  left: rem(-12);
  @include mq('md') {
    width: rem(191);
    top: initial;
    left: 0;
    bottom: rem(-60);
  }
}

.c-button.-size-thick > .c-button__deco {
  width: rem(152);
  top: rem(-40);
  left: rem(-16);
  @include mq('md') {
    width: rem(279);
    top: initial;
    bottom: rem(-48);
    left: rem(-60);
  }
}

.c-button__deco::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.c-button.-size-thick > .c-button__deco::before {
  content: "";
  display: block;
  padding-top: calc(70 / 152 * 100%);
}

.c-button__deco > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
